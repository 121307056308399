import React, { useRef } from "react";
import { Animated, ImageStyle, StyleProp, StyleSheet, View } from "react-native";

export const LazyImage = (props: {
  uri: string;
  color: string;
  style?: StyleProp<ImageStyle>;
}) => {
  const loaded = useRef(false);
  const opacity = useRef(new Animated.Value(1)).current;
  return (
    <View style={[{ position: "relative" }, props.style]}>
      <View
        style={[
          StyleSheet.absoluteFill,
          props.style,
          { backgroundColor: props.color },
        ]}
      />
      <Animated.Image
        style={[props.style, { opacity }]}
        source={{ uri: props.uri }}
        onLoadStart={() => {
          if (!loaded.current) {
            opacity.setValue(0);
          }
        }}
        onLoadEnd={() => {
          Animated.timing(opacity, {
            toValue: 1,
            duration: 300,
            useNativeDriver: true,
          }).start();
          loaded.current = true;
        }}
      />
    </View>
  );
};
