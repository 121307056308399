import {
  ColorSchemeName,
  Dimensions,
  useColorScheme as _useColorScheme,
} from "react-native";

const width = Dimensions.get("window").width;
const height = Dimensions.get("window").height;

export const Layouts = {
  window: {
    width,
    height,
  },
  isSmallDevice: width < 375,
};

export const Animation = {
  fadeInUp: {
    0: { opacity: 0, translateY: 10 },
    1: { opacity: 1, translateY: 0 },
  },
};

export const Colors = {
  transparentWhite: "rgba(255,255,255,0)",
  white: "hsl(0, 0%, 100%)",
  grey0: "hsl(0, 0%, 95%)",
  grey1: "hsl(0, 0%, 90%)",
  grey2: "hsl(0, 0%, 80%)",
  grey3: "hsl(0, 0%, 60%)",
  grey4: "hsl(0, 0%, 30%)",
  grey5: "hsl(0, 0%, 20%)",
  grey6: "hsl(0, 0%, 10%)",
  black: "hsl(0, 0%, 0%)",
  orange: "#f07c04",
  green: "#2bbc66",
  green7: "hsl(144,53%,35%)",
  yellow: "#f9d407",
  blue1: "#4ddde0",
  blue3: "#409de5",
  blue4: "#409de5",
  blue7: "#1f3c89",
  red1: "#f6a7b8",
  red3: "hsl(360,97%,64%)",
  red4: "#e95751",
  purple4: "#8e71ed",
  purple7: "hsl(254,58%,49%)",
};

export type Color = typeof Colors[keyof typeof Colors];

export const Fonts = {
  legalese: 10,
  detail: 12,
  small: 14,
  body: 16,
  h6: 18,
  h5: 20,
  h4: 24,
  h3: 30,
  h2: 36,
  h1: 48,
  h0: 60,
  h00: 72,
};

export type Font = typeof Fonts[keyof typeof Fonts];

export const LineHeight = {
  body: Fonts.body + 12,
};

export const Spacings = {
  tiny: 4,
  smallest: 8,
  smaller: 12,
  small: 16,
  medium: 24,
  large: 32,
  larger: 48,
  largest: 64,
  huge: 96,
};

export type Spacing = typeof Spacings[keyof typeof Spacings];

const light = {
  text: Colors.black,
  background: "#fff",
  textTint: Colors.grey4,
};

type Theme = typeof light;

export const Themes: {
  // eslint-disable-next-line no-unused-vars
  [schemeName in NonNullable<ColorSchemeName>]: Theme;
} = {
  light,
  dark: {
    text: "#fff",
    background: "#222222",
    textTint: Colors.grey2,
  },
};
// The useColorScheme value is always either light or dark, but the built-in
// type suggests that it can be null. This will not happen in practice, so this
// makes it a bit easier to work with.
export function useColorScheme(): NonNullable<ColorSchemeName> {
  return _useColorScheme() as NonNullable<ColorSchemeName>;
}

export function useTheme(): Theme {
  const scheme = useColorScheme();
  return Themes[scheme];
}

export function useThemeColor(
  props: { light?: string; dark?: string },
  colorName: keyof typeof Themes.light & keyof typeof Themes.dark,
) {
  const scheme = useColorScheme();
  const colorFromProps = props[scheme];

  if (colorFromProps) {
    return colorFromProps;
  } else {
    return Themes[scheme][colorName];
  }
}
