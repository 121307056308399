/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type WalkScreenQueryVariables = {
    id: string;
};
export type WalkScreenQueryResponse = {
    readonly Walk: {
        readonly _id: string | null;
        readonly name: string | null;
        readonly descriptionRaw: unknown | null;
        readonly length: number | null;
        readonly stops: ReadonlyArray<{
            readonly _id: string | null;
            readonly name: string | null;
            readonly icon: {
                readonly asset: {
                    readonly url: string | null;
                } | null;
            } | null;
            readonly heroImage: {
                readonly asset: {
                    readonly url: string | null;
                } | null;
            } | null;
            readonly location: {
                readonly lat: number | null;
                readonly lng: number | null;
            } | null;
        } | null> | null;
    } | null;
};
export type WalkScreenQuery = {
    readonly response: WalkScreenQueryResponse;
    readonly variables: WalkScreenQueryVariables;
};



/*
query WalkScreenQuery(
  $id: ID!
) {
  Walk(id: $id) {
    _id
    name
    descriptionRaw
    length
    stops {
      _id
      name
      icon {
        asset {
          url
        }
      }
      heroImage {
        asset {
          url
        }
      }
      location {
        lat
        lng
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SanityImageAsset",
    "kind": "LinkedField",
    "name": "asset",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "Walk",
    "kind": "LinkedField",
    "name": "Walk",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "descriptionRaw",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "length",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Location",
        "kind": "LinkedField",
        "name": "stops",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "icon",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "heroImage",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Geopoint",
            "kind": "LinkedField",
            "name": "location",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lat",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lng",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WalkScreenQuery",
    "selections": (v4/*: any*/),
    "type": "RootQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WalkScreenQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "2755111d81c5f53430ca636450001668",
    "id": null,
    "metadata": {},
    "name": "WalkScreenQuery",
    "operationKind": "query",
    "text": "query WalkScreenQuery(\n  $id: ID!\n) {\n  Walk(id: $id) {\n    _id\n    name\n    descriptionRaw\n    length\n    stops {\n      _id\n      name\n      icon {\n        asset {\n          url\n        }\n      }\n      heroImage {\n        asset {\n          url\n        }\n      }\n      location {\n        lat\n        lng\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'f88bdaf2ff4cff65da3a6777e2e012d3';
export default node;
