/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type MapScreenQueryVariables = {};
export type MapScreenQueryResponse = {
    readonly allLocation: ReadonlyArray<{
        readonly _id: string | null;
        readonly icon: {
            readonly asset: {
                readonly url: string | null;
            } | null;
        } | null;
        readonly location: {
            readonly lat: number | null;
            readonly lng: number | null;
        } | null;
    }>;
};
export type MapScreenQuery = {
    readonly response: MapScreenQueryResponse;
    readonly variables: MapScreenQueryVariables;
};



/*
query MapScreenQuery {
  allLocation {
    _id
    icon {
      asset {
        url
      }
    }
    location {
      lat
      lng
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Location",
    "kind": "LinkedField",
    "name": "allLocation",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Image",
        "kind": "LinkedField",
        "name": "icon",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SanityImageAsset",
            "kind": "LinkedField",
            "name": "asset",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Geopoint",
        "kind": "LinkedField",
        "name": "location",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lat",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lng",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MapScreenQuery",
    "selections": (v0/*: any*/),
    "type": "RootQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "MapScreenQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "2c37ee903acf95f30d70c4463725c9c6",
    "id": null,
    "metadata": {},
    "name": "MapScreenQuery",
    "operationKind": "query",
    "text": "query MapScreenQuery {\n  allLocation {\n    _id\n    icon {\n      asset {\n        url\n      }\n    }\n    location {\n      lat\n      lng\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '8400adb11d17e5e6367c63afe3e0971a';
export default node;
