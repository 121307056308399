import { Ionicons } from "@expo/vector-icons";
import { format, parseISO } from "date-fns";
import React, { Suspense, useState } from "react";
import { Dimensions, ScrollView, StyleSheet, TouchableOpacity } from "react-native";
import { graphql, useLazyLoadQuery } from "react-relay";
import { BlockContent } from "../components/BlockContent";
import { Container } from "../components/Container";
import { FocusAwareStatusBar } from "../components/FocusAwareStatusBar";
import { ImageHeader } from "../components/ImageHeader";
import { LoadingScreen } from "../components/LoadingScreen";
import { FiraText, OpenText } from "../components/Themed";
import { resize } from "../config";
import { EventStackNavigationProp } from "../navigation";
import { Colors, Fonts, Spacings, useTheme } from "../Theme";
import { EventScreenQuery } from "./__generated__/EventScreenQuery.graphql";

const styles = StyleSheet.create({
  back: {
    left: 0,
    padding: Spacings.medium,
    top: 0,
  },
  container: {
    flex: 1,
    flexDirection: "column",
  },
  content: {
    marginTop: Spacings.large,
  },
  date: {
    color: Colors.white,
    fontSize: Fonts.h4,
    marginBottom: Spacings.large,
  },
  hero: {
    position: "relative",
  },
  heroContent: {
    justifyContent: "flex-end",
  },
  heroContentContainer: {
    flex: 1,
  },
  title: {
    flex: 1,
    color: Colors.white,
    fontSize: Fonts.h1,
    fontWeight: "bold",
    marginBottom: Spacings.smallest,
  },
});

export function EventScreenComponent(props: EventStackNavigationProp<"Event">) {
  const [scrollPosition, setScrollPosition] = useState(0);
  const theme = useTheme();
  const data = useLazyLoadQuery<EventScreenQuery>(
    graphql`
      query EventScreenQuery($id: ID!) {
        Event(id: $id) {
          _id
          name
          date
          datetime
          heroImage {
            asset {
              url
            }
          }
          contentRaw
        }
      }
    `,
    {
      id: props.route.params.eventId,
    }
  );
  const event = data.Event!;
  return (
    <ScrollView
      contentContainerStyle={{ paddingBottom: Spacings.larger }}
      onScroll={(event) => setScrollPosition(event.nativeEvent.contentOffset.y)}
      scrollEventThrottle={128}
      style={[styles.container, { backgroundColor: theme.background }]}
    >
      <ImageHeader
        uri={resize(event.heroImage?.asset?.url!, {
          width: Dimensions.get("window").width,
        })}
        style={styles.hero}
      >
        <FocusAwareStatusBar
          barStyle={scrollPosition > 300 ? "dark-content" : "light-content"}
        />
        <TouchableOpacity
          style={styles.back}
          onPress={() => props.navigation.goBack()}
        >
          <Ionicons color={Colors.white} size={30} name="arrow-back-outline" />
        </TouchableOpacity>
        <Container
          style={styles.heroContent}
          containerStyle={styles.heroContentContainer}
        >
          <FiraText style={styles.title}>
            {event.name}
          </FiraText>
          <OpenText style={styles.date}>
            {event.datetime != null
              ? format(parseISO(event.datetime as string), "dd/MM/YYY HH:mm")
              : format(parseISO(event.date as string), "dd/MM/YYY")}
          </OpenText>
        </Container>
      </ImageHeader>
      <Container style={styles.content}>
        <BlockContent blocks={event.contentRaw} />
      </Container>
    </ScrollView>
  );
}

export function EventScreen(props: EventStackNavigationProp<"Event">) {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <EventScreenComponent {...props} />
    </Suspense>
  );
}
