import React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

export const Logo = (props: SvgProps) => (
  <Svg viewBox="0 0 1000 323.1" {...props}>
    <Path
      fill="#4b9bd5"
      d="M821,34.81,744.31,143.39l-10.59,14.75v.17a25,25,0,0,1-10.4,9.89c-5.89,3.29-13,5.2-21.51,5.37H669.37L703.54.29h1.05l32.6-.17H773.8a43.55,43.55,0,0,1,6.59.52,67.13,67.13,0,0,1,14.22,3.81C809.18,10.35,817.85,20.41,821,34.81Z"
    />
    <Path
      fill="#1e3d87"
      d="M848.21,173.57H815.78c-8.51-.17-15.62-2.08-21.52-5.37-9.19-5-13.87-13.19-13.87-24.46v-.35H744.31L821,34.81Z"
    />
    <Path
      fill="#4b9bd5"
      d="M55.78,102.17H0V0H115.69a40.05,40.05,0,0,1,17.18,3.64Z"
    />
    <Path
      fill="#f4a7b8"
      d="M158.71,50.65v71.46a56.07,56.07,0,0,1-6.07,24.81c-8.5,17.69-24.11,26.36-46.83,26.36L0,173.46,132.87,3.64a33,33,0,0,1,10.58,6.59C153.69,19.08,158.71,32.61,158.71,50.65Z"
    />
    <Path
      fill="#796fb1"
      d="M204,173.52q-12.63-.51-21.43-5.43-13.86-7.62-13.89-24.56V0l32.53.08q12.79,0,21.93,5.17,13.4,7.62,13.39,24.73V173.52Z"
    />
    <Path
      fill="#6ac4d0"
      d="M405.2,66.09v54.64q0,32.52-20.3,45.79c-7.28,4.51-14.74,6.94-22.89,6.94h-62.8a68.83,68.83,0,0,1-19.76-3.12l77-104.25Z"
    />
    <Path
      fill="#e95952"
      d="M279.45,170.34a44,44,0,0,1-27.07-23.59,54.41,54.41,0,0,1-5.9-24.12v-72c0-18,5-31.4,15.26-40.42A40.71,40.71,0,0,1,289.5,0H405.2Z"
    />
    <Path
      fill="#f9d507"
      d="M450.56,173.52q-12.6-.51-21.41-5.43-13.89-7.62-13.89-24.56V0l32.52.08q12.78,0,21.92,5.17Q483.1,12.87,483.09,30V173.52Z"
    />
    <Path
      fill="#45b066"
      d="M551.71,65.78H526.47A38,38,0,0,1,505.63,60q-12.53-8-12.53-24.22V.14L643,.06q12.71,0,21.68,5.25Q677.91,13,677.9,30.12V65.78H619.46V173.52H586.94q-12.45-.51-21.26-5.34-13.87-7.62-14-24.31Z"
    />
    <Path
      fill="#796fb1"
      d="M921.33,120.75H1000l-36.32,52.77h-75a43.43,43.43,0,0,1-21.24-5.17q-14-7.8-14-24.73V.06L886,.14q12.81,0,21.78,5.08,13,7.38,13.38,24Z"
    />
    <Path fill="#4b9bd5" d="M23,259.84H.78V244.28H62.26v15.56H40.11V322H23Z" />
    <Path
      fill="#4b9bd5"
      d="M132.14,322l-20.76-29.4V322H94.26V244.28h25.65c20.26,0,28,10.1,28,24s-7.81,21-18.57,23L153.5,322Zm-20.76-62.81V279.6h6.87c3.5,0,12.55,0,12.55-10.28s-8.93-10.14-12.55-10.14Z"
    />
    <Path
      fill="#4b9bd5"
      d="M207.91,244.28h14.5L253.93,322H235.58l-5.64-15.67H200.38L194.8,322H176.39Zm17,48-9.81-27.67-9.82,27.67Z"
    />
    <Path fill="#4b9bd5" d="M284.9,244.28H302V322H284.9Z" />
    <Path fill="#4b9bd5" d="M341.7,244.28h17.12v62.15h27.84V322h-45Z" />
    <Path
      fill="#4b9bd5"
      d="M474.79,299.5c0,12.89-8.53,22.49-30.41,22.49H418.73V244.28h22.15c21.24,0,28.22,10,28.22,20.58,0,7.42-3.46,13.06-8.87,15.46C468.37,282.33,474.79,288.24,474.79,299.5Zm-38.94-40.32v16.67h9c5.91,0,7.86-4.12,7.86-8.25,0-5.13-2.95-8.42-9-8.42Zm21.81,39.16c0-8.82-7.86-8.82-11-8.82H435.85v17.62h10.83C449.68,307.14,457.66,307.14,457.66,298.34Z"
    />
    <Path fill="#4b9bd5" d="M508.63,244.28h17.12v62.15h27.84V322h-45Z" />
    <Path
      fill="#4b9bd5"
      d="M608.46,244.28H623L654.48,322H636.13l-5.64-15.67H600.93L595.35,322H576.94Zm17,48-9.81-27.67-9.82,27.67Z"
    />
    <Path
      fill="#4b9bd5"
      d="M704.92,306.43h32V322H678.47l33.7-62.15H680.76V244.28h58Z"
    />
    <Path
      fill="#4b9bd5"
      d="M771.73,244.28h45v15.56H788.85v14.28h26.72v15.51H788.85v16.8h27.84V322h-45Z"
    />
    <Path
      fill="#4b9bd5"
      d="M892.2,322l-20.76-29.4V322H854.32V244.28H880c20.26,0,28,10.1,28,24s-7.82,21-18.58,23L913.56,322Zm-20.76-62.81V279.6h6.87c3.51,0,12.55,0,12.55-10.28s-8.93-10.14-12.55-10.14Z"
    />
    <Path
      fill="#4b9bd5"
      d="M939.76,296.38h17.85c0,8.6,6,12.83,14.17,12.83,5.68,0,11-2.07,11-8.48,0-9.26-10.93-9.7-21.59-12.55-9.26-2.51-19.08-6.64-19.08-20.81,0-16.9,14-24.2,27.56-24.2s28.12,7.19,28.12,24.76H981c-.61-8-5.41-10.82-11.65-10.82s-10,2.62-10,7.87c0,7.41,8.09,8.37,17,10.26,11.27,2.51,23.65,6.3,23.65,23,0,19.08-16.34,24.88-30,24.88C954.38,323.1,939.42,315.35,939.76,296.38Z"
    />
  </Svg>
);
