import {
  FiraSans_100Thin,
  FiraSans_100Thin_Italic,
  FiraSans_200ExtraLight,
  FiraSans_200ExtraLight_Italic,
  FiraSans_300Light,
  FiraSans_300Light_Italic,
  FiraSans_400Regular,
  FiraSans_400Regular_Italic,
  FiraSans_500Medium,
  FiraSans_500Medium_Italic,
  FiraSans_600SemiBold,
  FiraSans_600SemiBold_Italic,
  FiraSans_700Bold,
  FiraSans_700Bold_Italic,
  FiraSans_800ExtraBold,
  FiraSans_800ExtraBold_Italic,
  FiraSans_900Black,
  FiraSans_900Black_Italic
} from "@expo-google-fonts/fira-sans";
import {
  OpenSans_300Light,
  OpenSans_300Light_Italic,
  OpenSans_400Regular,
  OpenSans_400Regular_Italic,
  OpenSans_600SemiBold,
  OpenSans_600SemiBold_Italic,
  OpenSans_700Bold,
  OpenSans_700Bold_Italic,
  OpenSans_800ExtraBold,
  OpenSans_800ExtraBold_Italic
} from "@expo-google-fonts/open-sans";
import { Ionicons } from "@expo/vector-icons";
import * as Font from "expo-font";
import * as SplashScreen from "expo-splash-screen";
import React from "react";
import { useAsset } from "use-asset";

async function loadResourcesAndDataAsync() {
  try {
    await Font.loadAsync({
      ...Ionicons.font,
      FiraSans_100Thin,
      FiraSans_100Thin_Italic,
      FiraSans_200ExtraLight,
      FiraSans_200ExtraLight_Italic,
      FiraSans_300Light,
      FiraSans_300Light_Italic,
      FiraSans_400Regular,
      FiraSans_400Regular_Italic,
      FiraSans_500Medium,
      FiraSans_500Medium_Italic,
      FiraSans_600SemiBold,
      FiraSans_600SemiBold_Italic,
      FiraSans_700Bold,
      FiraSans_700Bold_Italic,
      FiraSans_800ExtraBold,
      FiraSans_800ExtraBold_Italic,
      FiraSans_900Black,
      FiraSans_900Black_Italic,
      OpenSans_300Light,
      OpenSans_300Light_Italic,
      OpenSans_400Regular,
      OpenSans_400Regular_Italic,
      OpenSans_600SemiBold,
      OpenSans_600SemiBold_Italic,
      OpenSans_700Bold,
      OpenSans_700Bold_Italic,
      OpenSans_800ExtraBold,
      OpenSans_800ExtraBold_Italic,
    });
  } catch (e) {
    // TODO We might want to provide this error information to an error reporting service
    console.warn(e);
  } finally {
    await SplashScreen.hideAsync();
  }
}

export function useLoadResources() {
  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {
    SplashScreen.preventAutoHideAsync().catch(() => {});
  }, []);
  useAsset(loadResourcesAndDataAsync);
}
