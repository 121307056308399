import React, { Suspense } from "react";
import { Image, Platform, SafeAreaView, ScrollView, StyleSheet, View } from "react-native";
import { graphql, useLazyLoadQuery } from "react-relay";
import { BlockContent } from "../components/BlockContent";
import { Container } from "../components/Container";
import { FocusAwareStatusBar } from "../components/FocusAwareStatusBar";
import { LoadingScreen } from "../components/LoadingScreen";
import { Logo } from "../components/Logo";
import { Version } from "../components/Version";
import { TabNavigationProp } from "../navigation";
import { Colors, Fonts, Spacings, useColorScheme, useTheme } from "../Theme";
import { AboutScreenQuery } from "./__generated__/AboutScreenQuery.graphql";

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    paddingHorizontal: 0,
  },
  image: {
    resizeMode: "cover",
  },
  imageContainer: {
    borderColor: Colors.white,
    borderWidth: 3,
  },
  screen: {
    flex: 1,
  },

  webContentContainer: {
    flex: 1,
    marginBottom: Spacings.large,
    marginTop: Spacings.larger,
  },
  webContentLogo: {
    height: Platform.OS === 'web' ? 170: 110,
    marginBottom: Spacings.large,
  },
  webContentAbout: {},
  webContentStoreButtons: {
    flexDirection: "row",
    marginBottom: Spacings.medium,
  },
  webContentImages: {
    flexDirection: "row",
    marginTop: Spacings.small,
    marginRight: -Spacings.small,
  },
  webContentImage: {
    resizeMode: "contain",
    marginRight: Spacings.small,
  },
  webContentParagraph: {
    marginBottom: Spacings.medium,
  },
  collapse: {},
  collapseLabel: {
    borderWidth: 2,
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: Spacings.medium,
    padding: Spacings.smaller,
  },
  collapseHeading: {
    fontSize: Fonts.h2,
    marginRight: Spacings.medium,
  },
});

export function AboutScreenComponent(props: TabNavigationProp<"About">) {
  const scheme = useColorScheme();
  const theme = useTheme();
  const data = useLazyLoadQuery<AboutScreenQuery>(
    graphql`
      query AboutScreenQuery($documentId: ID!) {
        About(id: $documentId) {
          _id
          name
          contentRaw
        }
      }
    `,
    { documentId: props.route.params?.documentId ?? "about" }
  );
  return (
    <SafeAreaView
      style={[styles.screen, { backgroundColor: theme.background }]}
    >
      <FocusAwareStatusBar
        barStyle={scheme === "dark" ? "light-content" : "dark-content"}
      />
      <ScrollView
        style={styles.container}

      >
        <Container style={styles.webContentContainer}>
          <Logo style={styles.webContentLogo} />
          <BlockContent blocks={data.About?.contentRaw} />
          <View style={styles.webContentImages}>
            <Image
              source={require("../../assets/images/uwe-logo.png")}
              style={[
                styles.webContentImage,
                {
                  width: 85 * (805 / 403),
                  height: 85,
                },
              ]}
            />
            <Image
              source={require("../../assets/images/deti-logo.jpg")}
              style={[
                styles.webContentImage,
                {
                  width: 85 * (1693 / 403),
                  height: 85,
                },
              ]}
            />
          </View>
          <Version />
        </Container>
      </ScrollView>
    </SafeAreaView>
  );
}

export function AboutScreen(props: TabNavigationProp<"About">) {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <AboutScreenComponent {...props} />
    </Suspense>
  );
}
