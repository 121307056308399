import {
  Environment,
  Network,
  RecordSource,
  Store,
} from "relay-runtime";
import { config } from "./config";
import { Json } from "./Json";
import { delay } from "./Promise";
import * as Random from "./Random";

const sendRequest =
  () =>
  async (params: { name: string; text?: string | null }, variables: Json) => {
    if (config.enableGraphQlQueryLogging) {
      console.debug(`GraphQL ${params.name} query to ${config.graphQlApi}`, {
        variables,
        query: params.text,
      });
    }
    if (__DEV__) {
      await delay(Random.int(200, 2000));
    }
    const headers: { [name: string]: string } = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    const isPreview =
      variables &&
      typeof variables === "object" &&
      !Array.isArray(variables) &&
      Object.entries(variables).some(([key, value]) => {
        return (
          typeof value === "string" &&
          key.toLowerCase().endsWith("id") &&
          value.startsWith("draft")
        );
      });
    // When previewing a document the user is redirected from SanityCMS to the
    // preview page with an id.
    //
    // Draft versions can only be accessed by authenticated users so we need to
    // detect when we are about to request one and add an authentication header.
    //
    // Fortunately the Id's of draft documents are always prefixed with `drafts.`:
    // https://www.sanity.io/docs/drafts#ebfb408fa9b6
    //
    // We don't want to always send this header since there may be an incomplete
    // draft version of an existing document returned from a collection.
    if (isPreview) {
      // This token has read only access
      headers["Authorization"] =
        "Bearer skEB04Y0MVhfcElv4MzXdBIpXrCGT2nrQYf1pCjdxSg31iffsNNB0CcUCkWNzDQJpsR7KDKTLmgpjZNrOP5sc14SpIjiWxDeNjHjbKgtU6ouKCK4Z9z0oMqxjNCIpHkj33JUSAjbjJSPhHMkHZgDM30dGQI1wMrnJNyDrBq8Rhrfzt3ECfwc";
    }
    const response = await fetch(
      isPreview || __DEV__ ? config.graphQlApi : config.graphQlCdnApi,
      {
        method: "POST",
        headers,
        body: JSON.stringify({
          query: params.text,
          variables,
        }),
      },
    );

    const body = await response.text();
    if (config.enableGraphQlQueryLogging) {
      console.debug(response.status, { body });
    }
    const json = JSON.parse(body);
    if (
      response.status === 403 ||
      (response.status === 401 &&
        JSON.parse(body)?.error?.startsWith("Invalid authorization"))
    ) {
      return json;
    } else if (!response.ok) {
      console.error(response.status, response.statusText, body);
      throw new Error(body);
    }
    return json;
  };

export const environment = () =>
  new Environment({
    network: Network.create(sendRequest()),
    store: new Store(new RecordSource()),
  });
