import React, { ReactNode } from "react";
import { ImageBackground, SafeAreaView, StyleProp, StyleSheet, ViewStyle } from "react-native";

const styles = StyleSheet.create({
  hero: {
    alignItems: "center",
    minHeight: 300,
    position: "relative",
  },
  heroContentContainer: {
    backgroundColor: "rgba(0,0,0,0.3)",
  },
  heroImage: {
    resizeMode: "cover",
    width: "100%",
  },
  heroContent: {
    flex: 1,
    width: "100%",
  },
});

export const ImageHeader = (props: {
  children?: ReactNode;
  style?: StyleProp<ViewStyle>;
  uri: string;
}) => {
  return (
    <ImageBackground
      source={{ uri: props.uri }}
      style={[styles.hero, props.style]}
      imageStyle={styles.heroImage}
    >
      <SafeAreaView
        style={[styles.heroContent, styles.heroContentContainer]}
      >
        {props.children}
      </SafeAreaView>
    </ImageBackground>
  );
};
