import { Ionicons } from "@expo/vector-icons";
import React, { Suspense } from "react";
import {
  Dimensions,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import { graphql, useLazyLoadQuery } from "react-relay";
import { BlockContent } from "../components/BlockContent";
import { Container } from "../components/Container";
import { ImageHeader } from "../components/ImageHeader";
import { LoadingScreen } from "../components/LoadingScreen";
import { FiraText } from "../components/Themed";
import { resize } from "../config";
import { ResourceStackNavigationProp } from "../navigation";
import { Colors, Fonts, Spacings, useTheme } from "../Theme";
import { ResourceScreenQuery } from "./__generated__/ResourceScreenQuery.graphql";

const styles = StyleSheet.create({
  back: {
    left: 0,
    padding: Spacings.medium,
    top: 0,
  },
  container: {
    flex: 1,
    flexDirection: "column",
  },
  content: {
    marginTop: Spacings.large,
  },
  hero: {
    position: "relative",
  },
  heroContent: {
    justifyContent: "flex-end",
  },
  heroContentContainer: { flex: 1 },
  title: {
    color: Colors.white,
    fontSize: Fonts.h1,
    fontWeight: "bold",
    marginBottom: Spacings.large,
  },
});

export function ResourceScreenComponent(
  props: ResourceStackNavigationProp<"Resource">,
) {
  const theme = useTheme();
  const data = useLazyLoadQuery<ResourceScreenQuery>(
    graphql`
      query ResourceScreenQuery($id: ID!) {
        Resource(id: $id) {
          _id
          name
          heroImage {
            asset {
              url
            }
          }
          contentRaw
        }
      }
    `,
    {
      id: props.route.params.resourceId,
    },
  );
  const resource = data.Resource!;
  return (
    <ScrollView
      contentContainerStyle={{ paddingBottom: Spacings.larger }}
      style={[styles.container, { backgroundColor: theme.background }]}
    >
      <ImageHeader
        uri={resize(resource.heroImage?.asset?.url!, {
          width: Dimensions.get("window").width,
        })}
        style={styles.hero}
      >
        <TouchableOpacity
          style={styles.back}
          onPress={() => props.navigation.goBack()}
        >
          <Ionicons color={Colors.white} size={30} name="arrow-back-outline" />
        </TouchableOpacity>
        <Container
          style={styles.heroContent}
          containerStyle={styles.heroContentContainer}
        >
          <FiraText style={styles.title}>{resource.name}</FiraText>
        </Container>
      </ImageHeader>
      <Container style={styles.content}>
        <BlockContent blocks={resource.contentRaw} />
      </Container>
    </ScrollView>
  );
}

export function ResourceScreen(props: ResourceStackNavigationProp<"Resource">) {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <ResourceScreenComponent {...props} />
    </Suspense>
  );
}
