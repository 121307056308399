/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type WalkLocationScreenQueryVariables = {
    id: string;
};
export type WalkLocationScreenQueryResponse = {
    readonly Walk: {
        readonly _id: string | null;
        readonly stops: ReadonlyArray<{
            readonly _id: string | null;
            readonly name: string | null;
            readonly color: {
                readonly hex: string | null;
            } | null;
            readonly heroImage: {
                readonly asset: {
                    readonly url: string | null;
                } | null;
            } | null;
        } | null> | null;
    } | null;
};
export type WalkLocationScreenQuery = {
    readonly response: WalkLocationScreenQueryResponse;
    readonly variables: WalkLocationScreenQueryVariables;
};



/*
query WalkLocationScreenQuery(
  $id: ID!
) {
  Walk(id: $id) {
    _id
    stops {
      _id
      name
      color {
        hex
      }
      heroImage {
        asset {
          url
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "Walk",
    "kind": "LinkedField",
    "name": "Walk",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Location",
        "kind": "LinkedField",
        "name": "stops",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Color",
            "kind": "LinkedField",
            "name": "color",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hex",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "heroImage",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SanityImageAsset",
                "kind": "LinkedField",
                "name": "asset",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WalkLocationScreenQuery",
    "selections": (v2/*: any*/),
    "type": "RootQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WalkLocationScreenQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "652f33d77fb36fb2f264fb080f3a8c63",
    "id": null,
    "metadata": {},
    "name": "WalkLocationScreenQuery",
    "operationKind": "query",
    "text": "query WalkLocationScreenQuery(\n  $id: ID!\n) {\n  Walk(id: $id) {\n    _id\n    stops {\n      _id\n      name\n      color {\n        hex\n      }\n      heroImage {\n        asset {\n          url\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'f6567e7953495f369275fc7d644b8892';
export default node;
