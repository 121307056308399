import React, { Suspense } from "react";
import { SafeAreaView, ScrollView, StyleSheet, View as RNView } from "react-native";
import { graphql, useLazyLoadQuery } from "react-relay";
import { BlockContent } from "../components/BlockContent";
import { Container } from "../components/Container";
import { ImageButton } from "../components/ImageButton";
import { LoadingScreen } from "../components/LoadingScreen";
import { FiraText } from "../components/Themed";
import { resize } from "../config";
import { ResourceStackNavigationProp } from "../navigation";
import { Colors, Fonts, Spacings, useTheme } from "../Theme";
import { ElementOf } from "../types";
import { ResourcesScreenQuery, ResourcesScreenQueryResponse } from "./__generated__/ResourcesScreenQuery.graphql";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
  },
  resourceLabel: {
    color: Colors.white,
    fontSize: Fonts.h3,
    fontWeight: "bold",
  },
  resources: {
    flexDirection: "column",
    marginBottom: Spacings.small,
  },
  title: {
    color: Colors.red4,
    fontSize: Fonts.h2,
    fontWeight: "bold",
    marginVertical: Spacings.large,
  },
});

type Resource = ElementOf<ResourcesScreenQueryResponse["allResource"]>;

export function ResourcesScreenComponent(
  props: ResourceStackNavigationProp<"Resources">
) {
  const theme = useTheme();
  const data = useLazyLoadQuery<ResourcesScreenQuery>(
    graphql`
      query ResourcesScreenQuery($documentId: ID!) {
        Resources(id: $documentId) {
          _id
          name
          contentRaw
        }
        allResource {
          _id
          name
          heroImage {
            asset {
              url
            }
          }
          color {
            hex
          }
        }
      }
    `,
    { documentId: props.route.params?.documentId ?? "resources" }
  );
  return (
    <SafeAreaView
      style={[styles.container, { backgroundColor: theme.background }]}
    >
      <ScrollView>
        <Container>
          <FiraText style={styles.title}>Resources</FiraText>
          {data.Resources && <BlockContent blocks={data.Resources?.contentRaw} />}
          <RNView style={styles.resources}>
            {data.allResource?.map(
              (resource: Resource) =>
                resource && (
                  <ImageButton
                    key={resource._id}
                    imageUri={resize(resource.heroImage?.asset?.url!, {
                      width: Container.width(),
                    })}
                    onPress={() => {
                      props.navigation.navigate("Resource", {
                        resourceId: resource._id!,
                      });
                    }}
                  >
                    <FiraText style={styles.resourceLabel}>
                      {resource.name}
                    </FiraText>
                  </ImageButton>
                )
            )}
          </RNView>
        </Container>
      </ScrollView>
    </SafeAreaView>
  );
}

export function ResourcesScreen(
  props: ResourceStackNavigationProp<"Resources">
) {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <ResourcesScreenComponent {...props} />
    </Suspense>
  );
}
