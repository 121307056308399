import React, { ReactNode } from "react";
import { Dimensions, StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { Spacings } from "../Theme";

const maxContainerWidth = 600;

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    flexDirection: "column",
  },
  content: {
    flex: 1,
    maxWidth: maxContainerWidth,
    paddingHorizontal: Spacings.medium,
    width: "100%",
  },
});

export const Container = (props: {
  children?: ReactNode;
  containerStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
}) => {
  return (
    <View style={[styles.container, props.containerStyle]}>
      <View style={[styles.content, props.style]}>{props.children}</View>
    </View>
  );
};

Container.spacing = Spacings.medium;

Container.width = () => {
  return Math.min(Dimensions.get("window").width, maxContainerWidth);
};
