export type Size = [height: number, width: number];

export const MapIcon = ({
  image,
  size,
}: {
  image?: string | null;
  size: Size;
}) => `
  <div style="background: rgb(198,69,100);background: linear-gradient(60deg, rgba(198,69,100,1) 0%, rgba(218,190,97,1) 87%);border-radius: 44px;width:44px;height:44px;position:relative;top:${
    -size[1] / 2
  }px;left:${0}px;">
    <div style="position:absolute;top:3px;left:3px;background-color: transparent;width:38px; height:38px;border-radius: 38px; overflow: hidden">
      ${
        image
          ? `<img src="${image}" width="38" height="38" style="border-radius: 38px;position:absolute;top:0;left:0;"/>`
          : `<span>📍</span>`
      }
    </div>
  </div>`;

MapIcon.Size = [44, 44] as Size;
