import { StackScreenProps } from "@react-navigation/stack";
import React from "react";
import { Platform, StyleSheet, View } from "react-native";
import SvgQRCode from "react-native-qrcode-svg";
import { graphql, useLazyLoadQuery } from "react-relay";
import { Button, ButtonLabel } from "../components/Button";
import { FiraText, OpenText } from "../components/Themed";
import { RootStackParamList } from "../navigation";
import { Fonts, Spacings } from "../Theme";
import { PreviewScreenQuery } from "./__generated__/PreviewScreenQuery.graphql";
import { AboutScreen } from "./AboutScreen";
import { EventScreen } from "./EventScreen";
import { EventsScreen } from "./EventsScreen";
import { ExploreScreen } from "./ExploreScreen";
import { LocationScreen } from "./LocationScreen";
import { ResourceScreen } from "./ResourceScreen";
import { ResourcesScreen } from "./ResourcesScreen";
import { WalkScreen } from "./WalkScreen";

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    backgroundColor: "#fff",
    flex: 1,
    justifyContent: "center",
    padding: Spacings.large,
  },
  options: {
    alignItems: "center",
    flexDirection: "row",
    flex: 1,
    width: "100%",
  },
  option: {
    alignItems: "center",
    flex: 1,
    flexDirection: "column",
    height: "100%",
  },
  title: {
    fontSize: Fonts.h2,
    fontWeight: "bold",
    marginBottom: Spacings.medium,
  },
  details: {
    fontSize: Fonts.h6,
  },
  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },
});

export function PreviewScreen(
  props: StackScreenProps<RootStackParamList, "Preview">
) {
  const documentId = props.route.params?.id;
  if (documentId == null) {
    throw new Error(
      "Navigated to preview screen without a documentId to preview"
    );
  }
  const data = useLazyLoadQuery<PreviewScreenQuery>(
    graphql`
      query PreviewScreenQuery($documentId: ID!) {
        Document(id: $documentId) {
          _id
          _type
        }
      }
    `,
    {
      documentId,
    }
  );
  if (data.Document == null) {
    return (
      <View style={styles.container}>
        <FiraText style={styles.title}>Missing document</FiraText>
        <OpenText>This document couldn't be previewed</OpenText>
      </View>
    );
  }
  if (
    Platform.OS !== "web" ||
    (Platform.OS === "web" && props.route.params.showOnWeb)
  ) {
    if (data.Document._type === "about") {
      return (
        <AboutScreen
          route={{ ...props.route, name: "About", params: { documentId } }}
          navigation={props.navigation as any}
        />
      );
    } else if (data.Document._type === "event") {
      return (
        <EventScreen
          route={{
            ...props.route,
            name: "Event",
            params: { eventId: documentId },
          }}
          navigation={props.navigation as any}
        />
      );
    } else if (data.Document._type === "events") {
      return (
        <EventsScreen
          route={{ ...props.route, name: "Events", params: { documentId } }}
          navigation={props.navigation as any}
        />
      );
    } else if (data.Document._type === "explore") {
      return (
        <ExploreScreen
          route={{ ...props.route, name: "Explore", params: { documentId } }}
          navigation={props.navigation as any}
        />
      );
    } else if (data.Document._type === "location") {
      return (
        <LocationScreen
          route={{
            ...props.route,
            name: "Location",
            params: { locationId: documentId },
          }}
          navigation={props.navigation as any}
        />
      );
    } else if (data.Document._type === "resource") {
      return (
        <ResourceScreen
          route={{
            ...props.route,
            name: "Resource",
            params: { resourceId: documentId },
          }}
          navigation={props.navigation as any}
        />
      );
    } else if (data.Document._type === "resources") {
      return (
        <ResourcesScreen
          route={{ ...props.route, name: "Resources", params: { documentId } }}
          navigation={props.navigation as any}
        />
      );
    } else if (data.Document._type === "walk") {
      return (
        <WalkScreen
          route={{
            ...props.route,
            name: "Trail",
            params: { trailId: documentId },
          }}
          navigation={props.navigation as any}
        />
      );
    } else {
      return (
        <View style={styles.container}>
          <FiraText style={styles.title}>Unrecognised document</FiraText>
          <OpenText>Developer information</OpenText>
          <OpenText>Id</OpenText>
          <OpenText>{documentId}</OpenText>
          <OpenText>Type</OpenText>
          <OpenText>{data.Document._type}</OpenText>
        </View>
      );
    }
  }
  return (
    <View style={styles.container}>
      <View style={styles.options}>
        <View style={styles.option}>
          <FiraText style={styles.title}>Web</FiraText>
          <Button
            onPress={() =>
              props.navigation.setParams({
                showOnWeb: true,
              })
            }
          >
            <ButtonLabel>Continue to web preview</ButtonLabel>
          </Button>
        </View>
        <View style={styles.option}>
          <FiraText style={styles.title}>Mobile</FiraText>
          <OpenText style={{ textAlign: "center" }}>
            Scan this with your camera app to open it in the Digital
            Trailblazers App
          </OpenText>
          <View
            style={{
              marginTop: Spacings.medium,
              marginBottom: Spacings.largest,
            }}
          >
            <SvgQRCode
              size={150}
              value={`digital-trailblazers://preview?id=${props.route.params.id}`}
            />
          </View>
        </View>
      </View>
    </View>
  );
}
