import React from "react";
import { ActivityIndicator, StyleSheet } from "react-native";
import { useTheme } from "../Theme";
import { View } from "./Themed";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});

export const LoadingScreen = () => {
  const theme = useTheme();
  return (
    <View style={styles.container}>
      <ActivityIndicator color={theme.text} />
    </View>
  );
};
