/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type LocationScreenQueryVariables = {
    id: string;
};
export type LocationScreenQueryResponse = {
    readonly Location: {
        readonly name: string | null;
        readonly icon: {
            readonly asset: {
                readonly url: string | null;
            } | null;
        } | null;
        readonly heroImage: {
            readonly asset: {
                readonly url: string | null;
            } | null;
        } | null;
        readonly location: {
            readonly lat: number | null;
            readonly lng: number | null;
        } | null;
        readonly digitalLink: string | null;
        readonly outreachLink: string | null;
        readonly whatsOnLink: string | null;
        readonly contentRaw: unknown | null;
    } | null;
};
export type LocationScreenQuery = {
    readonly response: LocationScreenQueryResponse;
    readonly variables: LocationScreenQueryVariables;
};



/*
query LocationScreenQuery(
  $id: ID!
) {
  Location(id: $id) {
    name
    icon {
      asset {
        url
      }
    }
    heroImage {
      asset {
        url
      }
    }
    location {
      lat
      lng
    }
    digitalLink
    outreachLink
    whatsOnLink
    contentRaw
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SanityImageAsset",
    "kind": "LinkedField",
    "name": "asset",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "Location",
    "kind": "LinkedField",
    "name": "Location",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Image",
        "kind": "LinkedField",
        "name": "icon",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Image",
        "kind": "LinkedField",
        "name": "heroImage",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Geopoint",
        "kind": "LinkedField",
        "name": "location",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lat",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lng",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "digitalLink",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "outreachLink",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "whatsOnLink",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "contentRaw",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LocationScreenQuery",
    "selections": (v2/*: any*/),
    "type": "RootQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LocationScreenQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "537841b88ad9f724a7d75d4a0ae92848",
    "id": null,
    "metadata": {},
    "name": "LocationScreenQuery",
    "operationKind": "query",
    "text": "query LocationScreenQuery(\n  $id: ID!\n) {\n  Location(id: $id) {\n    name\n    icon {\n      asset {\n        url\n      }\n    }\n    heroImage {\n      asset {\n        url\n      }\n    }\n    location {\n      lat\n      lng\n    }\n    digitalLink\n    outreachLink\n    whatsOnLink\n    contentRaw\n  }\n}\n"
  }
};
})();
(node as any).hash = '1725e44bb42f4effe6d0093da9413396';
export default node;
