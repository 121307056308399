import { Ionicons } from "@expo/vector-icons";
import polyline from "@mapbox/polyline";
import { ExpoLeaflet, MapMarker, MapShape } from "expo-leaflet";
import React, { Suspense, useEffect, useMemo, useRef, useState } from "react";
import {
  Animated,
  FlatList,
  Platform,
  SafeAreaView,
  StyleSheet,
  TouchableOpacity,
  useWindowDimensions,
  ViewToken,
} from "react-native";
import * as Animatable from "react-native-animatable";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { graphql, useLazyLoadQuery } from "react-relay";
import { Button, ButtonLabel } from "../components/Button";
import { FocusAwareStatusBar } from "../components/FocusAwareStatusBar";
import { LazyImage } from "../components/LazyImage";
import { LoadingScreen } from "../components/LoadingScreen";
import { MapLayers, MapOptions } from "../components/Map";
import { MapIcon } from "../components/MapIcon";
import { FiraText, View } from "../components/Themed";
import { config, resize } from "../config";
import { boundary, LatLng, zoomLevelFor } from "../coordinates";
import { WalkStackNavigationProp } from "../navigation";
import { Animation, Colors, Fonts, Spacings, useTheme } from "../Theme";
import { ElementOf } from "../types";
import {
  WalksScreenQuery,
  WalksScreenQueryResponse,
} from "./__generated__/WalksScreenQuery.graphql";

const bristolHarbour = {
  lat: 51.45523,
  lng: -2.59665,
};

const walkCardImageWidth = 100 - Spacings.smaller * 2;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "flex-end",
    position: "relative",
  },
  map: {
    bottom: 0,
    flex: 1,
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
    width: "100%",
  },
  mapControls: {
    flexDirection: "column",
  },
  mapResetButton: { backgroundColor: Colors.green, flexDirection: "row" },
  mapResetButtonContainer: { position: "absolute" },
  mapResetButtonLabel: { marginRight: Spacings.tiny },
  walkCardContainer: {
    backgroundColor: Colors.white,
    borderRadius: 22,
    elevation: 6,
    flexDirection: "row",
    padding: Spacings.smaller,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.27,
    shadowRadius: 4.65,
  },
  walkCardDetail: {
    fontSize: Fonts.small,
    marginTop: Spacings.smallest,
  },
  walkCardDetails: {
    flexDirection: "column",
    marginHorizontal: Spacings.small,
    shadowColor: Colors.white,
  },
  walkCardImage: {
    borderRadius: 13,
    height: 100 - Spacings.smaller * 2,
    resizeMode: "cover",
    width: walkCardImageWidth,
  },
  walkCardList: {},
  walkCardTitle: {
    fontSize: Fonts.h6,
    fontWeight: "bold",
  },
  walkCards: {
    backgroundColor: Colors.transparentWhite,
    flexDirection: "row",
    margin: -5,
    paddingBottom: Spacings.medium,
  },
  walkCardsListContainer: { alignItems: "center" },
  webScrollControl: {
    alignItems: "center",
    backgroundColor: Colors.white,
    borderRadius: 10,
    elevation: 6,
    justifyContent: "center",
    marginBottom: Spacings.small,
    marginHorizontal: Spacings.tiny,
    marginTop: -Spacings.small,
    padding: Spacings.smallest,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.27,
    shadowRadius: 4.65,
  },
  webScrollControls: {
    backgroundColor: Colors.transparentWhite,
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },
});

type Walk = ElementOf<WalksScreenQueryResponse["allWalk"]>;

type Stop = ElementOf<ElementOf<WalksScreenQueryResponse["allWalk"]>["stops"]>;

// These were captured using the 'cycle' profile with a ferry exclusion
// const url = `https://api.mapbox.com/directions/v5/mapbox/cycling/${points}?access_token=${config.mapboxToken}&exclude=ferry`;
const brunelLoopPath = `[{"lat":51.45509664910908,"lng":-2.627293467521668},{"lat":51.44679258260433,"lng":-2.6182919740676884},{"lat":51.44897572227211,"lng":-2.6078474521636967},{"lat":51.44759831705431,"lng":-2.5981593132019047},{"lat":51.44896569277265,"lng":-2.5834876298904423},{"lat":51.45509664910908,"lng":-2.627293467521668}]`;
const brunelLoopGeometry =
  "my`yHhc`OtNqCjM_IbFmKdAgDbBmTfG|@f@KQsAf@QE[DZAqBj@iASyDs@}A`BiBqB}j@{GqDmBuBg@z@~AiCrBuG`@cCLHzBuNv@oJ_CuLyA{FoHR_BYx@}W`AcQb@aX_@gElBgAd@iCWi@Vh@e@hCmBfA^fEc@`X_AjPgAz]}@rJqBhBdA|OXjNd@d@]bEzBx@q@fDUvDBpGfDtUTE`BrIzAhEKhERjACjCeAhIu@bMqGzOcNxIuNpC";
const harboursideTrailPath = `[{"lat":51.44897572227211,"lng":-2.6078474521636967},{"lat":51.44759831705431,"lng":-2.5981593132019047},{"lat":51.4504099180124,"lng":-2.600487470626831},{"lat":51.44679258260433,"lng":-2.6182919740676884},{"lat":51.44897572227211,"lng":-2.6078474521636967}]`;
const harborsideTrailGeometry =
  "ws_yHvf|N~AiCrBuG`@cCLHzBuNv@oJ_CuLyA{FoHR_BYiAxOmApAF`CJhC]Nj@xGXjNd@d@]bEzBx@q@fDUvDBpGfDtUTE`BrIzAhEKhERjACjCW~AfG|@f@KQsAf@QE[DZAqBj@iASyDs@}A`BiBqB}j@{GqDmBuBg@z@";

const cache: { [path: string]: string } = {
  [brunelLoopPath]: brunelLoopGeometry,
  [harboursideTrailPath]: harborsideTrailGeometry,
};

const useDetailedPath = (stops: LatLng[]): LatLng[] => {
  const [detailedPath, setDetailedPath] = useState(stops);
  useEffect(() => {
    try {
      const pathString = JSON.stringify(stops);
      if (cache[pathString] != null) {
        setDetailedPath(
          polyline
            .decode(cache[pathString])
            .map(([lat, lng]) => ({ lat, lng })) ?? []
        );
      } else {
        setDetailedPath(stops);
        const points = stops.map(({ lat, lng }) => `${lng},${lat}`).join(";");
        const url = `https://api.mapbox.com/directions/v5/mapbox/walking/${points}?access_token=${config.mapboxToken}&walkway_bias=1`;
        fetch(url)
          .then((response) => {
            return response.json();
          })
          .then((response) => {
            const geomtry = response?.routes?.[0].geometry;
            cache[pathString] = geomtry;
            console.warn("failed to read from path cache", pathString, geomtry);
            const details =
              polyline.decode(geomtry).map(([lat, lng]) => ({ lat, lng })) ??
              [];
            setDetailedPath(details);
          })
          .catch((error) => {
            console.error(error);
            setDetailedPath(stops);
          });
      }
    } catch (error) {
      console.error(error);
    }
  }, [stops]);
  return detailedPath;
};

const useIsMovingMap = (): [boolean, () => void] => {
  const [isMoving, setMoving] = useState(false);
  useEffect(() => {
    const effect = setTimeout(() => {
      setMoving(false);
    }, 4000);
    return () => {
      clearTimeout(effect);
    };
  }, [isMoving]);

  return [isMoving, () => setMoving(true)];
};

export function WalksScreenComponent(props: WalkStackNavigationProp<"Trails">) {
  const flatList = useRef<FlatList>(null!);
  const theme = useTheme();
  const windowDimensions = useWindowDimensions();
  const ITEM_SIZE = windowDimensions.width * 0.76;
  const SPACER_ITEM_SIZE = (windowDimensions.width - ITEM_SIZE) / 2;
  const [key, reset] = useState(0);
  const [hasMovedMap, setMovedMap] = useState(false);
  const [isMovingMap, setIsMovingMap] = useIsMovingMap();
  const safeArea = useSafeAreaInsets();
  const data = useLazyLoadQuery<WalksScreenQuery>(
    graphql`
      query WalksScreenQuery {
        allWalk {
          _id
          name
          isCircuit
          length
          stops {
            _id
            icon {
              asset {
                url
              }
            }
            color {
              hex
            }
            heroImage {
              asset {
                url
              }
            }
            location {
              lat
              lng
            }
          }
        }
      }
    `,
    {}
  );
  const walks = useMemo(
    () => [{ _id: "left-padding" }, ...data.allWalk, { _id: "right-padding" }],
    [data.allWalk]
  );
  const [visibleWalkState, setVisibleWalk] = useState<{
    walk: Walk;
    index: number;
  } | null>({ walk: data.allWalk?.[0], index: 0 });
  const { walk: visibleWalk, index: visibleIndex } = visibleWalkState ?? {
    walk: null,
    index: null,
  };
  const onViewableItemsChanged = useRef(
    ({
      viewableItems,
    }: {
      viewableItems: ViewToken[];
      changed: ViewToken[];
    }): void => {
      if (viewableItems.length === 3) {
        setIsMovingMap();
        setVisibleWalk({
          walk: viewableItems[1]?.item,
          index: viewableItems[1]?.index!,
        });
      }
    }
  ).current;

  const positions = useMemo(() => {
    const positions =
      visibleWalk?.stops?.map((stop) => ({
        lat: stop?.location?.lat!,
        lng: stop?.location?.lng!,
      })) ?? [];
    if (visibleWalk?.isCircuit && (visibleWalk?.stops?.length ?? 0) > 1) {
      positions.push(positions[0]);
    }
    return positions;
  }, [visibleWalk]);

  const detailed = useDetailedPath(positions);

  const path: MapShape = {
    shapeType: "polyline",
    color: Colors.purple4,
    positions,
    dashArray: [4],
  } as MapShape;

  const detailedPath: MapShape = {
    shapeType: "polyline",
    color: Colors.purple4,
    positions: detailed,
    dashArray: [4],
  } as MapShape;

  const bounds = boundary(positions, 0.2);
  const mapCenter = visibleWalk
    ? {
        lat: (bounds.northEast.lat + bounds.southWest.lat) / 2,
        lng: (bounds.northEast.lng + bounds.southWest.lng) / 2,
      }
    : bristolHarbour;

  const zoom = zoomLevelFor(windowDimensions, bounds);

  const scrollX = useRef(new Animated.Value(0)).current;

  return (
    <SafeAreaView style={styles.container}>
      <FocusAwareStatusBar barStyle={"dark-content"} />
      <View style={styles.map}>
        <ExpoLeaflet
          key={key}
          backgroundColor={theme.background}
          mapCenterPosition={mapCenter}
          mapLayers={MapLayers}
          mapMarkers={visibleWalk?.stops?.map(
            (stop: Stop): MapMarker => ({
              id: stop?._id!,
              position: {
                lat: stop?.location?.lat!,
                lng: stop?.location?.lng!,
              },
              icon: MapIcon({
                image: stop?.icon?.asset?.url,
                size: MapIcon.Size,
              }),
              size: MapIcon.Size,
            })
          )}
          mapShapes={detailed.length > 0 ? [detailedPath] : [path]}
          mapOptions={MapOptions}
          onMessage={(message) => {
            if (message.tag === "onMoveEnd" || message.tag === "onZoomEnd") {
              setMovedMap(true);
            }
            if (message.tag === "onMapMarkerClicked") {
              props.navigation.navigate("Map", {
                screen: "Location",
                params: {
                  locationId: message.mapMarkerId,
                },
              });
            }
          }}
          zoom={zoom - (Platform.OS === "web" ? 1 : 0)}
        />
      </View>
      {hasMovedMap && !isMovingMap && (
        <Animatable.View
          animation={Animation.fadeInUp}
          delay={300}
          duration={300}
          useNativeDriver={true}
          style={[
            styles.mapResetButtonContainer,
            { top: safeArea.top, right: safeArea.right },
          ]}
        >
          <SafeAreaView>
            <Button
              onPress={() => {
                setMovedMap(false);
                reset((k) => k + 1);
              }}
              style={styles.mapResetButton}
            >
              <ButtonLabel style={styles.mapResetButtonLabel}>
                Reset map
              </ButtonLabel>
              <Ionicons
                name="refresh-outline"
                size={Fonts.body}
                color={"white"}
              />
            </Button>
          </SafeAreaView>
        </Animatable.View>
      )}

      <Animatable.View
        animation={Animation.fadeInUp}
        delay={300}
        duration={300}
        useNativeDriver={true}
        style={styles.mapControls}
      >
        <View style={styles.walkCards}>
          <Animated.FlatList
            contentContainerStyle={styles.walkCardsListContainer}
            bounces={false}
            data={walks}
            decelerationRate={0}
            horizontal={true}
            keyExtractor={(item) => item._id!}
            onScroll={Animated.event(
              [{ nativeEvent: { contentOffset: { x: scrollX } } }],
              { useNativeDriver: true }
            )}
            onViewableItemsChanged={onViewableItemsChanged}
            ref={flatList}
            scrollEventThrottle={16}
            showsHorizontalScrollIndicator={false}
            snapToAlignment="start"
            snapToInterval={ITEM_SIZE}
            style={styles.walkCardList}
            renderItem={({ index, item }) => {
              if (index === 0 || index === data.allWalk.length + 1) {
                return (
                  <View
                    style={{
                      width: SPACER_ITEM_SIZE,
                    }}
                  />
                );
              }

              const walk = item as Walk;
              const inputRange = [
                (index - 2) * ITEM_SIZE,
                (index - 1) * ITEM_SIZE,
                index * ITEM_SIZE,
              ];
              const translateY = scrollX.interpolate({
                inputRange,
                outputRange: [10, 0, 10],
              });
              const scale = scrollX.interpolate({
                inputRange,
                outputRange: [0.8, 1, 0.8],
              });

              return (
                <View
                  style={{
                    width: ITEM_SIZE,
                    backgroundColor: Colors.transparentWhite,
                  }}
                >
                  <Animated.View
                    // eslint-disable-next-line react-native/no-inline-styles
                    style={{
                      backgroundColor: theme.background,
                      borderRadius: 22,
                      // Don't lose the shadow
                      marginBottom: 10,
                      transform: [{ scale }, { translateY }],
                    }}
                  >
                    <TouchableOpacity
                      activeOpacity={0.8}
                      style={[
                        styles.walkCardContainer,
                        { backgroundColor: theme.background },
                      ]}
                      onPress={() => {
                        props.navigation.navigate("Trail", {
                          trailId: walk._id!,
                        });
                      }}
                    >
                      <LazyImage
                        color={walk.stops?.[0]?.color?.hex ?? Colors.white}
                        uri={resize(walk.stops?.[0]?.heroImage?.asset?.url!, {
                          width: walkCardImageWidth,
                        })}
                        style={styles.walkCardImage}
                      />
                      <View style={styles.walkCardDetails}>
                        <FiraText
                          style={styles.walkCardTitle}
                          numberOfLines={1}
                        >
                          {walk.name}
                        </FiraText>
                        <FiraText
                          style={[
                            styles.walkCardDetail,
                            { color: theme.textTint },
                          ]}
                        >
                          {walk.stops?.length} stops
                        </FiraText>
                        <FiraText
                          style={[
                            styles.walkCardDetail,
                            { color: theme.textTint },
                          ]}
                        >
                          {walk.length ?? (walk.stops?.length ?? 3) * 15}{" "}
                          minutes
                        </FiraText>
                      </View>
                    </TouchableOpacity>
                  </Animated.View>
                </View>
              );
            }}
          />
        </View>
        {Platform.OS === "web" && (
          <View style={styles.webScrollControls}>
            {(visibleIndex ?? 0) > 1 && (
              <TouchableOpacity
                onPress={() => {
                  if ((visibleIndex ?? 0) > 0) {
                    flatList.current.scrollToIndex({
                      index: visibleIndex! - 1,
                      viewOffset: SPACER_ITEM_SIZE,
                    });
                  }
                }}
                style={[
                  styles.webScrollControl,
                  { backgroundColor: theme.background },
                ]}
              >
                <Ionicons
                  name="arrow-back-outline"
                  size={30}
                  color={theme.text}
                />
              </TouchableOpacity>
            )}
            {(visibleIndex ?? 0) < walks.length - 2 && (
              <TouchableOpacity
                onPress={() => {
                  if ((visibleIndex ?? 0) > 0) {
                    flatList.current.scrollToIndex({
                      index: visibleIndex! + 1,
                      viewOffset: SPACER_ITEM_SIZE,
                    });
                  }
                }}
                style={[
                  styles.webScrollControl,
                  { backgroundColor: theme.background },
                ]}
              >
                <Ionicons
                  name="arrow-forward-outline"
                  size={30}
                  color={theme.text}
                />
              </TouchableOpacity>
            )}
          </View>
        )}
      </Animatable.View>
    </SafeAreaView>
  );
}

export function WalksScreen(props: WalkStackNavigationProp<"Trails">) {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <WalksScreenComponent {...props} />
    </Suspense>
  );
}
