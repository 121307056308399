import { Ionicons } from "@expo/vector-icons";
import { ExpoLeaflet, MapMarker } from "expo-leaflet";
import React, { Suspense, useState } from "react";
import { Platform, SafeAreaView, StyleSheet } from "react-native";
import * as Animatable from "react-native-animatable";
import { graphql, useLazyLoadQuery } from "react-relay";
import { Button, ButtonLabel } from "../components/Button";
import { FocusAwareStatusBar } from "../components/FocusAwareStatusBar";
import { LoadingScreen } from "../components/LoadingScreen";
import { MapLayers, MapOptions } from "../components/Map";
import { MapIcon } from "../components/MapIcon";
import { View } from "../components/Themed";
import { MapStackNavigationProp } from "../navigation";
import { Animation as Animations, Colors, Fonts, Spacings, useTheme } from "../Theme";
import { MapScreenQuery } from "./__generated__/MapScreenQuery.graphql";

const styles = StyleSheet.create({
  map: {
    flex: 1,
    width: "100%",
  },
  mapResetButtonContainer: { position: "absolute", top: 0, right: 0 },
  mapResetButton: { backgroundColor: Colors.blue4, flexDirection: "row" },
  mapResetButtonLabel: { marginRight: Spacings.tiny },
});

const initialMapPosition = {
  lat: 51.47223,
  lng: -2.59665,
};

const initialZoom = Platform.OS === "web" ? 11 : 12;

export function MapScreenComponent(props: MapStackNavigationProp<"Map">) {
  const [key, reset] = useState(0);
  const [hasMovedMap, setMovedMap] = useState(false);
  const theme = useTheme();
  const data = useLazyLoadQuery<MapScreenQuery>(
    graphql`
      query MapScreenQuery {
        allLocation {
          _id
          icon {
            asset {
              url
            }
          }
          location {
            lat
            lng
          }
        }
      }
    `,
    {}
  );
  return (
    <>
      <View style={styles.map}>
        <FocusAwareStatusBar barStyle={"dark-content"} />
        <ExpoLeaflet
          key={key}
          onMessage={(message) => {
            if (message.tag === "onMoveEnd" || message.tag === "onZoomEnd") {
              setMovedMap(true);
            }
            if (message.tag === "onMapMarkerClicked") {
              props.navigation.navigate("Location", {
                locationId: message.mapMarkerId,
              });
            }
          }}
          backgroundColor={theme.background}
          mapLayers={MapLayers}
          mapMarkers={data.allLocation.map(
            (location): MapMarker => ({
              id: location._id!,
              icon: MapIcon({
                image: location?.icon?.asset?.url,
                size: MapIcon.Size,
              }),
              position: {
                lat: location.location?.lat!,
                lng: location.location?.lng!,
              },
              size: MapIcon.Size,
            })
          )}
          mapOptions={MapOptions}
          mapCenterPosition={initialMapPosition}
          zoom={initialZoom}
        />
      </View>
      {hasMovedMap && (
        <Animatable.View
            animation={Animations.fadeInUp}
            delay={300}
            duration={300}
            useNativeDriver={true}
            style={styles.mapResetButtonContainer}
          >
        <SafeAreaView>
            <Button
              onPress={() => {
                setMovedMap(false);
                reset((k) => k + 1);
              }}
              style={styles.mapResetButton}
            >
              <ButtonLabel style={styles.mapResetButtonLabel}>
                Reset map
              </ButtonLabel>
              <Ionicons
                name="refresh-outline"
                size={Fonts.body}
                color={"white"}
              />
            </Button>
        </SafeAreaView>
          </Animatable.View>
      )}
    </>
  );
}

export function MapScreen(props: MapStackNavigationProp<"Map">) {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <MapScreenComponent {...props} />
    </Suspense>
  );
}
