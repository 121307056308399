import { Ionicons } from "@expo/vector-icons";
import React, { Suspense } from "react";
import { Platform, StyleSheet, TouchableOpacity, View as RNView } from "react-native";
import { graphql, useLazyLoadQuery } from "react-relay";
import { Container } from "../components/Container";
import { LazyImage } from "../components/LazyImage";
import { LoadingScreen } from "../components/LoadingScreen";
import { FiraText, OpenText, View } from "../components/Themed";
import { resize } from "../config";
import { WalkStackNavigationProp } from "../navigation";
import { Colors, Fonts, Spacings, useTheme } from "../Theme";
import { WalkLocationScreenQuery } from "./__generated__/WalkLocationScreenQuery.graphql";
import { LocationScreen } from "./LocationScreen";

const controlImageWidth = 55 - Spacings.smaller * 2;

const styles = StyleSheet.create({
  container: { flex: 1, width: "100%" },
  control: {
    alignItems: "center",
    borderColor: Colors.white,
    borderRadius: 8,
    borderWidth: 1,
    elevation: 6,
    flex: 1,
    flexDirection: "row",
    marginVertical: Spacings.smallest,
    paddingHorizontal: Spacings.smallest,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.27,
    shadowRadius: 4.65,
  },
  controlDetails: {
    flex: 1,
    flexDirection: "column",
    overflow: "hidden",
    paddingLeft: Spacings.smallest,
  },
  controlImage: {
    borderRadius: 5,
    height: 55 - Spacings.smaller * 2,
    resizeMode: "cover",
    width: controlImageWidth,
  },
  controlLabel: {
    fontSize: Fonts.small,
  },
  controlTitle: {
    fontSize: Fonts.body,
    fontWeight: "bold",
  },
  controls: {
    flexDirection: "row",
    justifyContent: "center",
  },
  controlsContainer: { minHeight: 60 },
});

export const WalkLocationScreenComponent = (
  props: WalkStackNavigationProp<"TrailLocation">
) => {
  const theme = useTheme();
  const data = useLazyLoadQuery<WalkLocationScreenQuery>(
    graphql`
      query WalkLocationScreenQuery($id: ID!) {
        Walk(id: $id) {
          _id
          stops {
            _id
            name
            color {
              hex
            }
            heroImage {
              asset {
                url
              }
            }
          }
        }
      }
    `,
    {
      id: props.route.params.trailId,
    }
  );
  const currentIndex = data.Walk?.stops?.findIndex(
    (stop) => stop?._id === props.route.params.locationId
  );
  const previousLocation =
    currentIndex == null || currentIndex === 0
      ? null
      : data.Walk?.stops?.[currentIndex - 1];
  const nextLocation =
    currentIndex == null || currentIndex === (data.Walk?.stops?.length ?? 1) - 1
      ? null
      : data.Walk?.stops?.[currentIndex + 1];

  const backControl = (
    <TouchableOpacity
      activeOpacity={0.8}
      style={[
        styles.control,
        // eslint-disable-next-line react-native/no-inline-styles
        {
          backgroundColor: theme.background,
          flex: 0,
          minWidth: 32,
        },
      ]}
      onPress={() => {
        props.navigation.goBack();
      }}
    >
      <Ionicons
        name={"arrow-back-outline"}
        size={Fonts.body}
        color={theme.text}
      />
    </TouchableOpacity>
  );
  return (
    <View style={styles.container}>
      <RNView style={styles.container}>
        <LocationScreen
          route={{ ...props.route, name: "Location" }}
          navigation={props.navigation as any}
        />
      </RNView>
      <Container
        style={styles.controls}
        containerStyle={styles.controlsContainer}
      >
        {previousLocation == null ? (
          backControl
        ) : Platform.OS === "web" ? (
          <TouchableOpacity
            activeOpacity={0.8}
            style={[
              styles.control,
              {
                backgroundColor: theme.background,
              },
            ]}
            onPress={() => {
              props.navigation.goBack();
            }}
          >
            <LazyImage
              color={previousLocation?.color?.hex ?? Colors.white}
              uri={previousLocation.heroImage?.asset?.url!}
              style={styles.controlImage}
            />
            <RNView style={styles.controlDetails}>
              <OpenText style={styles.controlLabel}>Previous</OpenText>
              <FiraText style={styles.controlTitle} numberOfLines={1}>
                {previousLocation.name}
              </FiraText>
            </RNView>
          </TouchableOpacity>
        ) : (
          backControl
        )}
        {nextLocation == null ? (
          <TouchableOpacity
            activeOpacity={0.8}
            style={[
              styles.control,
              {
                backgroundColor: theme.background,
                marginLeft: Spacings.small,
              },
            ]}
            onPress={() => {
              // @ts-ignore this works fine
              props.navigation.navigate("Trails");
            }}
          >
            <RNView style={styles.controlDetails}>
              <OpenText style={styles.controlLabel}>Back to map</OpenText>
            </RNView>
          </TouchableOpacity>
        ) : (
          <TouchableOpacity
            activeOpacity={0.8}
            style={[
              styles.control,
              {
                backgroundColor: theme.background,
                marginLeft: Spacings.small,
              },
            ]}
            onPress={() => {
              props.navigation.push("TrailLocation", {
                locationId: nextLocation._id!,
                trailId: props.route.params.trailId,
              });
            }}
          >
            <LazyImage
              color={nextLocation?.color?.hex ?? Colors.white}
              uri={resize(nextLocation.heroImage?.asset?.url!, {
                width: controlImageWidth,
              })}
              style={styles.controlImage}
            />
            <RNView style={styles.controlDetails}>
              <OpenText style={styles.controlLabel}>Next</OpenText>
              <FiraText style={styles.controlTitle} numberOfLines={1}>
                {nextLocation.name}
              </FiraText>
            </RNView>
          </TouchableOpacity>
        )}
      </Container>
    </View>
  );
};

export const WalkLocationScreen = (
  props: WalkStackNavigationProp<"TrailLocation">
) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <WalkLocationScreenComponent {...props} />
    </Suspense>
  );
};
