import { Ionicons } from "@expo/vector-icons";
import * as Linking from "expo-linking";
import React, { Suspense } from "react";
import {
  Dimensions,
  Platform,
  ScrollView,
  StatusBar,
  StyleSheet,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";
import { showLocation } from "react-native-map-link";
import { graphql, useLazyLoadQuery } from "react-relay";
import { BlockContent } from "../components/BlockContent";
import { Button, ButtonLabel } from "../components/Button";
import { Container } from "../components/Container";
import { ImageHeader } from "../components/ImageHeader";
import { LoadingScreen } from "../components/LoadingScreen";
import { FiraText, View } from "../components/Themed";
import { resize } from "../config";
import { MapStackNavigationProp } from "../navigation";
import { Colors, Fonts, Spacings, useTheme } from "../Theme";
import { LocationScreenQuery } from "./__generated__/LocationScreenQuery.graphql";

const styles = StyleSheet.create({
  back: {
    left: 0,
    padding: Spacings.medium,
    top: 0,
  },
  button: {},
  buttonIcon: { marginBottom: -3 },
  buttons: {
    flexDirection: "row",
    flexWrap: Platform.OS === "web" ? "nowrap" : "wrap",
  },
  container: {
    flex: 1,
    flexDirection: "column",
  },
  content: {
    marginTop: Spacings.large,
  },
  hero: {
    position: "relative",
  },
  heroContent: {
    justifyContent: "flex-end",
  },
  heroContentContainer: {
    flex: 1,
  },
  title: {
    color: Colors.white,
    fontSize: Fonts.h1,
    fontWeight: "bold",
    marginBottom: Spacings.large,
  },
});

export function LocationScreenComponent(
  props: MapStackNavigationProp<"Location">,
) {
  const dimensions = useWindowDimensions();
  const theme = useTheme();
  const data = useLazyLoadQuery<LocationScreenQuery>(
    graphql`
      query LocationScreenQuery($id: ID!) {
        Location(id: $id) {
          name
          icon {
            asset {
              url
            }
          }
          heroImage {
            asset {
              url
            }
          }
          location {
            lat
            lng
          }
          digitalLink
          outreachLink
          whatsOnLink
          contentRaw
        }
      }
    `,
    {
      id: props.route.params.locationId,
    },
  );
  const location = data.Location!;
  const buttonCount =
    (location.whatsOnLink != null ? 1 : 0) +
    (location.digitalLink != null ? 1 : 0) +
    (location.outreachLink != null ? 1 : 0) +
    1; // Take me there
  const buttonStyle =
    buttonCount === 4 && Platform.OS !== "web"
      ? {
          width: dimensions.width / 2,
        }
      : { flex: 1 };
  return (
    <ScrollView
      contentContainerStyle={{ paddingBottom: Spacings.larger }}
      style={[styles.container, { backgroundColor: theme.background }]}
    >
      <StatusBar barStyle="light-content" />
      <ImageHeader
        uri={resize(location.heroImage?.asset?.url!, {
          width: Dimensions.get("window").width,
        })}
        style={styles.hero}
      >
        <TouchableOpacity
          style={styles.back}
          onPress={() => props.navigation.goBack()}
        >
          <Ionicons color={Colors.white} size={30} name="arrow-back-outline" />
        </TouchableOpacity>
        <Container
          style={styles.heroContent}
          containerStyle={styles.heroContentContainer}
        >
          <FiraText style={styles.title}>{location.name}</FiraText>
        </Container>
      </ImageHeader>
      <View style={styles.buttons}>
        {location.whatsOnLink != null && (
          <Button
            style={[
              styles.button,
              buttonStyle,
              {
                backgroundColor: Colors.orange,
              },
            ]}
            onPress={() => {
              Linking.openURL(location.whatsOnLink!);
            }}
          >
            <ButtonLabel>Whats On</ButtonLabel>
            <Ionicons
              color={Colors.white}
              size={20}
              style={styles.buttonIcon}
              name="calendar-outline"
            />
          </Button>
        )}
        {location.outreachLink != null && (
          <Button
            style={[
              styles.button,
              buttonStyle,
              {
                backgroundColor: Colors.blue4,
              },
            ]}
            onPress={() => {
              Linking.openURL(location.outreachLink!);
            }}
          >
            <ButtonLabel>Outreach</ButtonLabel>
            <Ionicons
              color={Colors.white}
              size={20}
              style={styles.buttonIcon}
              name="school-outline"
            />
          </Button>
        )}
        {location.digitalLink != null && (
          <Button
            style={[
              styles.button,
              buttonStyle,
              {
                backgroundColor: Colors.green,
              },
            ]}
            onPress={() => {
              Linking.openURL(location.digitalLink!);
            }}
          >
            <ButtonLabel>Digital</ButtonLabel>
            <Ionicons
              color={Colors.white}
              size={20}
              style={styles.buttonIcon}
              name="at-circle-outline"
            />
          </Button>
        )}
        <Button
          style={[styles.button, buttonStyle]}
          onPress={() => {
            if (Platform.OS === "web") {
              window.open(
                `https://maps.google.com?q=${location.location?.lat!},${location
                  .location?.lng!}`,
              );
            } else {
              showLocation({
                latitude: location?.location?.lat!,
                longitude: location?.location?.lng!,
                googleForceLatLon: true,
                title: location.name ?? undefined,
                alwaysIncludeGoogle: true,
                directionsMode: "walk",
              });
            }
          }}
        >
          <ButtonLabel>Take me there</ButtonLabel>
          <Ionicons
            color={Colors.white}
            size={20}
            style={styles.buttonIcon}
            name="compass-outline"
          />
        </Button>
      </View>
      <Container style={styles.content}>
        <BlockContent blocks={location.contentRaw} />
      </Container>
    </ScrollView>
  );
}

export function LocationScreen(props: MapStackNavigationProp<"Location">) {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <LocationScreenComponent {...props} />
    </Suspense>
  );
}
