import { Int } from "./types";

export function boolean() {
  return Math.random() > 0.5;
}

/** Generate an float in the inclusive range [0, 1] */
export function float() {
  return Math.random();
}

/** Generate an integer in the inclusive range [from, to] */
export const int = (from: Int = 0, to: Int = Number.MAX_SAFE_INTEGER): Int => {
  return Math.round(Math.random() * (to - from)) + from;
};

export class Seeded {
  constructor(private seed: Int) {}

  private next(): number {
    return (
      ((2 ** 31 - 1) & (this.seed = Math.imul(48271, this.seed))) / 2 ** 31
    );
  }

  boolean: () => boolean = () => {
    return this.next() > 0.5;
  };

  /** Generate an float in the inclusive range [0, 1] */
  float: () => number = this.next;

  /** Generate an integer in the inclusive range [from, to] */
  int(from: Int = 0, to: Int = Number.MAX_SAFE_INTEGER): Int {
    return Math.round(this.next() * (to - from)) + from;
  }
}
