import { MapLayer } from "expo-leaflet";
import { Platform } from "react-native";
import { config } from "../config";

export const MapLayers: Array<MapLayer> = [
  {
    baseLayerIsChecked: true,
    baseLayer: true,
    baseLayerName: "Mapbox",
    layerType: "TileLayer",
    url: `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${config.mapboxToken}`,
  },
];

export const MapOptions = {
  attributionControl: false,
  maxZoom: 18,
  zoomControl: Platform.OS === "web",
};
