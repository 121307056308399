import {
  BottomTabBarOptions,
  BottomTabBarProps,
  BottomTabNavigationProp,
  createBottomTabNavigator,
} from "@react-navigation/bottom-tabs";
import {
  CompositeNavigationProp,
  NavigatorScreenParams,
  RouteProp,
} from "@react-navigation/native";
import {
  createStackNavigator,
  StackNavigationProp,
} from "@react-navigation/stack";
import React, { useEffect } from "react";
import {
  Animated,
  Platform,
  SafeAreaView,
  StyleSheet,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { Logo } from "./components/Logo";
import { OpenText } from "./components/Themed";
import { Color, Colors, Fonts, Spacings } from "./Theme";
import { ID } from "./types";

export type MapStackScreens = {
  Map: undefined;
  Location: {
    locationId: ID;
  };
};

export type MapStackNavigationProp<Screen extends keyof MapStackScreens> = {
  route: RouteProp<MapStackScreens, Screen>;
  navigation: StackNavigationProp<MapStackScreens, Screen>;
};

export const MapStack = createStackNavigator<MapStackScreens>();

export type WalkStackScreens = {
  Trails: { documentId?: ID };
  Trail: {
    trailId: ID;
  };
  TrailLocation: {
    trailId: ID;
    locationId: ID;
  };
};

export type WalkStackNavigationProp<Screen extends keyof WalkStackScreens> = {
  route: RouteProp<WalkStackScreens, Screen>;
  navigation: CompositeNavigationProp<
    BottomTabNavigationProp<BottomTabParamList, "TrailsTab">,
    StackNavigationProp<WalkStackScreens, Screen>
  >;
};

export const WalkStack = createStackNavigator<WalkStackScreens>();

export type EventStackScreens = {
  Events: { documentId?: ID };
  Event: {
    eventId: ID;
  };
};

export type EventStackNavigationProp<Screen extends keyof EventStackScreens> = {
  route: RouteProp<EventStackScreens, Screen>;
  navigation: CompositeNavigationProp<
    BottomTabNavigationProp<BottomTabParamList, "Events">,
    StackNavigationProp<EventStackScreens, Screen>
  >;
};

export const EventStack = createStackNavigator<EventStackScreens>();

export type ResourceStackScreens = {
  Resources: { documentId?: ID };
  Resource: {
    resourceId: ID;
  };
};

export type ResourceStackNavigationProp<
  Screen extends keyof ResourceStackScreens,
> = {
  route: RouteProp<ResourceStackScreens, Screen>;
  navigation: CompositeNavigationProp<
    BottomTabNavigationProp<BottomTabParamList, "Resources">,
    StackNavigationProp<ResourceStackScreens, Screen>
  >;
};

export const ResourceStack = createStackNavigator<ResourceStackScreens>();

export type BottomTabParamList = {
  About: { documentId?: ID };
  Explore: { documentId?: ID };
  Map: NavigatorScreenParams<MapStackScreens>;
  Events: NavigatorScreenParams<EventStackScreens>;
  Resources: { documentId?: ID };
  TrailsTab: NavigatorScreenParams<WalkStackScreens>;
};

export type TabNavigationProp<Screen extends keyof BottomTabParamList> = {
  route: RouteProp<BottomTabParamList, Screen>;
  navigation: BottomTabNavigationProp<BottomTabParamList, Screen>;
};

export const BottomTabNavigator =
  createBottomTabNavigator<BottomTabParamList>();
export const BottomTabs: typeof BottomTabNavigator = {
  Navigator: ({ children, ...props }) => {
    return (
      <BottomTabNavigator.Navigator
        tabBar={(tabBarProps) => {
          return <AnimatedTabBar {...tabBarProps} />;
        }}
        {...props}
      >
        {children}
      </BottomTabNavigator.Navigator>
    );
  },
  Screen: BottomTabNavigator.Screen,
};

const tabs: {
  // eslint-disable-next-line no-unused-vars
  [key in keyof BottomTabParamList]: {
    color: Color;
    scale: Animated.Value;
  };
} = {
  About: {
    color: Colors.purple4,
    scale: new Animated.Value(0),
  },
  Explore: {
    color: Colors.purple4,
    scale: new Animated.Value(0),
  },
  Map: {
    color: Colors.blue4,
    scale: new Animated.Value(0),
  },
  TrailsTab: {
    color: Colors.green,
    scale: new Animated.Value(0),
  },
  Events: {
    color: Colors.orange,
    scale: new Animated.Value(0),
  },
  Resources: {
    color: Colors.red3,
    scale: new Animated.Value(0),
  },
};

const animatedTabBarStyles = StyleSheet.create({
  bar: {
    alignItems: "flex-start",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  button: {
    alignItems: "center",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
  },
  labelStyle: {
    color: Colors.white,
    fontSize: Platform.OS === "web" ? Fonts.body : Fonts.detail,
    marginTop: Spacings.tiny,
  },
});

const AnimatedTabBar = (props: BottomTabBarProps<BottomTabBarOptions>) => {
  const dimensions = useWindowDimensions();
  const offsets = useSafeAreaInsets();
  const { state, descriptors, navigation } = props;
  const focused = descriptors[state.routes[state.index].key];
  if (focused.options.tabBarVisible === false) {
    return null;
  }
  const activeName = state.routes[state.index].name as keyof BottomTabParamList;
  const activeConfig = tabs[activeName] ?? tabs["Map"];
  useEffect(() => {
    Object.entries(tabs).forEach(([name, { scale }]) => {
      Animated.timing(scale, {
        toValue: name === activeName ? 1 : 0,
        duration: 100,
        useNativeDriver: true,
      }).start();
    });
  }, [activeName]);

  return (
    <View
      style={[
        animatedTabBarStyles.bar,
        {
          backgroundColor: activeConfig.color,
          height: (Platform.OS === "web" ? 80 : 60) + offsets.bottom,
        },
      ]}
    >
      {Platform.OS === "web" && dimensions.width > 900 && (
        <TouchableOpacity
          accessibilityRole="button"
          accessibilityLabel={"Home"}
          onPress={() => {
            navigation.emit({
              type: "tabPress",
              target: state.routes[0].key,
              canPreventDefault: true,
            });
            navigation.navigate(state.routes[0].name);
          }}
          onLongPress={() => {
            navigation.emit({
              type: "tabLongPress",
              target: state.routes[0].key,
            });
          }}
          style={[
            animatedTabBarStyles.button,
            {
              backgroundColor: "rgba(0,0,0,0.3)",
              height: (Platform.OS === "web" ? 80 : 60) + offsets.bottom,
            },
          ]}
        >
          <Logo height={50} />
        </TouchableOpacity>
      )}
      {state.routes.map((route, index) => {
        const tabConfig =
          tabs[state.routes[index].name as keyof BottomTabParamList] ??
          tabs["Map"];
        const { options } = descriptors[route.key];
        const renderIcon = options.tabBarIcon ?? (() => null);
        const label =
          options.tabBarLabel !== undefined
            ? options.tabBarLabel
            : options.title !== undefined
            ? options.title
            : route.name;

        const isFocused = state.index === index;
        const color = isFocused ? Colors.white : "rgba(255,255,255,0.7)";

        const onPress = () => {
          const event = navigation.emit({
            type: "tabPress",
            target: route.key,
            canPreventDefault: true,
          });

          if (!isFocused && !event.defaultPrevented) {
            navigation.navigate(route.name);
          }
        };

        const onLongPress = () => {
          navigation.emit({
            type: "tabLongPress",
            target: route.key,
          });
        };

        return (
          <TouchableOpacity
            accessibilityRole="button"
            accessibilityState={isFocused ? { selected: true } : {}}
            accessibilityLabel={options.tabBarAccessibilityLabel}
            key={route.key}
            testID={options.tabBarTestID}
            onPress={onPress}
            onLongPress={onLongPress}
            style={[
              animatedTabBarStyles.button,
              { height: Platform.OS === "web" ? 80 : 60 },
            ]}
          >
            <Animated.View
              style={[
                animatedTabBarStyles.button,
                {
                  transform: [
                    {
                      scale: tabConfig.scale.interpolate({
                        inputRange: [0, 1],
                        outputRange: [0.85, 1],
                      }),
                    },
                  ],
                },
              ]}
            >
              {renderIcon({ color, focused: isFocused, size: 24 })}
              <OpenText style={[animatedTabBarStyles.labelStyle, { color }]}>
                {label}
              </OpenText>
            </Animated.View>
          </TouchableOpacity>
        );
      })}
    </View>
  );
};

export type RootStackParamList = {
  Root: undefined;
  Preview: {
    id?: string;
    showOnWeb?: boolean;
  };
  PrivacyPolicy: undefined;
  NotFound: undefined;
};

// A root stack navigator is often used for displaying modals on top of all other content
// Read more here: https://reactnavigation.org/docs/modal
export const RootStack = createStackNavigator<RootStackParamList>();
