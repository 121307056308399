// @ts-ignore
import PortableText from "@sanity/block-content-to-react";
import * as Linking from "expo-linking";
import getYouTubeId from "get-youtube-id";
import React, { ReactNode } from "react";
import { Image, StyleSheet, View } from "react-native";
import YouTube from "react-native-youtube-iframe";
import { buildFileUrl, buildImageUrl } from "../config";
import { Colors, Fonts, LineHeight, Spacings } from "../Theme";
import { FileLinkButton } from "./Button";
import { Container } from "./Container";
import { FiraText, OpenText } from "./Themed";

const styles = StyleSheet.create({
  imageContainer: {
    backgroundColor: "black",
    marginHorizontal: -Container.spacing,
  },
  image: {},
  imageCaption: {
    backgroundColor: Colors.white,
    color: Colors.grey4,
    fontStyle: "italic",
    paddingTop: Spacings.smaller,
    paddingHorizontal: Container.spacing,
    paddingBottom: Spacings.small,
  },
  videoContainer: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "black",
    marginHorizontal: -Container.spacing,
    marginBottom: Spacings.small,
  },
  blockquote: {
    paddingHorizontal: 14,
    borderLeftWidth: 3.5,
    borderLeftColor: "#dfe2e5",
    marginBottom: 16,
  },
  h0: { marginVertical: 22 },
  h1: { marginVertical: 20 },
  h2: { marginVertical: 18 },
  h3: { marginVertical: 18 },
  h4: { marginVertical: 18 },
  h5: { marginVertical: 18 },
  normal: { marginBottom: 16 },
  strong: {},
  em: {},
  link: {},
  underline: {},
  strikeThrough: {},
  code: {},
  list: { marginVertical: 16 },
  listItem: {
    flex: 1,
    flexDirection: "row",
  },
  listItemText: { flex: 1, flexWrap: "wrap", fontSize: Fonts.h6 },
  bulletlistIcon: {
    marginLeft: 10,
    marginRight: 10,
    fontWeight: "bold",
  },
  numberlistIcon: {},
  listItemWrapper: {
    flexDirection: "row",
    justifyContent: "flex-start",
  },
});

const textStyles = StyleSheet.create({
  h0: {
    fontWeight: "bold",
    fontSize: Fonts.h0,
  },
  h1: {
    fontWeight: "bold",
    fontSize: Fonts.h1,
  },
  h2: {
    fontWeight: "bold",
    fontSize: Fonts.h2,
  },
  h3: {
    fontWeight: "bold",
    fontSize: Fonts.h3,
  },
  h4: {
    fontWeight: "bold",
    fontSize: Fonts.h4,
  },
  h5: {
    fontWeight: "bold",
    fontSize: Fonts.h5,
  },
  normal: {
    fontSize: Fonts.h6,
    lineHeight: LineHeight.body,
  },
  strong: {
    fontWeight: "bold",
    lineHeight: LineHeight.body,
  },
  em: { fontStyle: "italic", lineHeight: LineHeight.body },
  link: { textDecorationLine: "underline", lineHeight: LineHeight.body },
  underline: { textDecorationLine: "underline", lineHeight: LineHeight.body },
  strikeThrough: {
    textDecorationLine: "line-through",
    lineHeight: LineHeight.body,
  },
  code: {
    paddingVertical: 3,
    paddingHorizontal: 5,
    backgroundColor: "rgba(27, 31, 35, 0.05)",
    color: "#24292e",
    lineHeight: LineHeight.body,
  },
});

const BlockTypeSerializer = (props: {
  children: ReactNode;
  node: { style?: keyof typeof textStyles };
}) => {
  const style = props.node.style || "normal";
  // Wrap in a text element to make children display inline
  const TextComponent = ['h1','h2'].includes(style) ? FiraText : OpenText;
  return (
    <View style={styles[style]}>
      <TextComponent style={textStyles[style]}>{props.children}</TextComponent>
    </View>
  );
};

const LinkSerializer = (props: {
  children: ReactNode;
  mark: { href: string };
}) => {
  const onPress = () => Linking.openURL(props.mark.href);
  return (
    <OpenText onPress={onPress} style={textStyles.link}>
      {props.children}
    </OpenText>
  );
};

const ListSerializer = (props: any) => {
  return (
    <View
      style={[
        styles.list,
        { paddingLeft: 16 * props.level },
        props.level > 1 ? { marginVertical: 0 } : {},
      ]}
    >
      {props.children}
    </View>
  );
};

const ListItemSerializer = (props: any) => {
  const type = props.node.listItem;
  const children =
    !props.node.style || props.node.style === "normal"
      ? // Don't wrap plain text in paragraphs inside of a list item
        props.children
      : // But wrap any other style in whatever the block serializer says to use
      <BlockTypeSerializer node={{}}>
        {props.children}
        </BlockTypeSerializer>

  if (type === "bullet") {
    return (
      <View key={props.node._key} style={styles.listItemWrapper}>
        <OpenText style={styles.bulletlistIcon}>{"\u00B7"}</OpenText>
        <View style={styles.listItem}>
          <OpenText style={styles.listItemText}>{children}</OpenText>
        </View>
      </View>
    );
  }

  if (type === "number") {
    return (
      <View key={props.node._key} style={styles.listItemWrapper}>
        <OpenText style={styles.numberlistIcon}>{`${
          props.index + 1
        }. `}</OpenText>
        ,<View style={styles.listItem}> {children}</View>
      </View>
    );
  }

  return (
    <View key={props.node._key} style={styles.listItem}>
      {children}
    </View>
  );
};

const HardBreakSerializer = () => <OpenText>{"\n"}</OpenText>;

const DownloadButton = (props: {
  node: {
    asset: { _ref: string };
    name: string;
  };
}) => {
  return (
    <FileLinkButton
      label={props.node.name}
      url={buildFileUrl(props.node.asset._ref)}
      style={{ marginBottom: Spacings.medium }}
    />
  );
};

type MarkProps = { children: ReactNode };

const serializers = {
  list: ListSerializer,
  listItem: ListItemSerializer,
  hardBreak: HardBreakSerializer,
  marks: {
    strong: (props: MarkProps) => {
      return <OpenText style={textStyles.strong}>{props.children}</OpenText>;
    },
    em: (props: MarkProps) => {
      return <OpenText style={textStyles.em}>{props.children}</OpenText>;
    },
    code: (props: MarkProps) => {
      return <OpenText style={textStyles.code}>{props.children}</OpenText>;
    },
    underline: (props: MarkProps) => {
      return <OpenText style={textStyles.underline}>{props.children}</OpenText>;
    },
    strikeThrough: (props: MarkProps) => {
      return (
        <OpenText style={textStyles.strikeThrough}>{props.children}</OpenText>
      );
    },
    link: LinkSerializer,
  },
  text: OpenText,
  types: {
    block: BlockTypeSerializer,
    bodyFile: DownloadButton,
    bodyImage: ({
      node: { image, alt, caption },
    }: {
      node: {
        image: { _type: "image"; asset: { _ref: string; _type: "reference" } };
        alt?: string;
        caption?: string;
      };
    }) => {
      const imageWidth = Container.width();
      const imageHeight = imageWidth * (9 / 16);
      return (
        <View style={styles.imageContainer}>
          <Image
            accessibilityLabel={alt}
            source={{
              uri: buildImageUrl(image.asset._ref, { width: imageWidth }),
            }}
            style={[styles.image, { height: imageHeight }]}
          />
          {caption && (
            <OpenText style={styles.imageCaption}>{caption}</OpenText>
          )}
        </View>
      );
    },
    youtube: ({ node: { url } }: { node: { url: string } }) => {
      const width = Container.width();
      const id = getYouTubeId(url)!;
      return (
        <View style={styles.videoContainer}>
          <YouTube videoId={id} height={(width / 16) * 9} width={width} />
        </View>
      );
    },
  },
  unknownMark: OpenText,
};

export function BlockContent({ blocks }: { blocks: unknown }) {
  return <PortableText blocks={blocks} serializers={serializers} />;
}
