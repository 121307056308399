import { Ionicons } from "@expo/vector-icons";
import React, { Suspense } from "react";
import {
  SafeAreaView,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import { graphql, useLazyLoadQuery } from "react-relay";
import { BlockContent } from "../components/BlockContent";
import { Container } from "../components/Container";
import { ImageButton } from "../components/ImageButton";
import { LoadingScreen } from "../components/LoadingScreen";
import { FiraText, View } from "../components/Themed";
import { resize } from "../config";
import { WalkStackNavigationProp } from "../navigation";
import { Colors, Fonts, Spacings, useTheme } from "../Theme";
import { ElementOf } from "../types";
import {
  WalkScreenQuery,
  WalkScreenQueryResponse,
} from "./__generated__/WalkScreenQuery.graphql";

const styles = StyleSheet.create({
  back: {
    marginLeft: -3,
    marginTop: Spacings.medium,
  },
  container: {
    flex: 1,
    flexDirection: "column",
  },
  content: {},
  stat: {
    fontSize: Fonts.h5,
    marginBottom: Spacings.medium,
  },
  stopLabel: {
    color: Colors.white,
    fontSize: Fonts.h3,
    fontWeight: "bold",
  },
  stops: {
    flexDirection: "column",
    marginBottom: Spacings.small,
  },
  stopsLabel: {
    fontFamily: "FiraSans_400Regular",
    fontSize: Fonts.h4,
    fontWeight: "bold",
    marginVertical: Spacings.small,
  },
  title: {
    fontSize: Fonts.h2,
    fontWeight: "bold",
    marginVertical: Spacings.large,
  },
});

type Stop = ElementOf<NonNullable<WalkScreenQueryResponse["Walk"]>["stops"]>;

export function WalkScreenComponent(props: WalkStackNavigationProp<"Trail">) {
  const theme = useTheme();
  const data = useLazyLoadQuery<WalkScreenQuery>(
    graphql`
      query WalkScreenQuery($id: ID!) {
        Walk(id: $id) {
          _id
          name
          descriptionRaw
          length
          stops {
            _id
            name
            icon {
              asset {
                url
              }
            }
            heroImage {
              asset {
                url
              }
            }
            location {
              lat
              lng
            }
          }
        }
      }
    `,
    {
      id: props.route.params.trailId,
    }
  );
  const walk = data.Walk!;
  return (
    <SafeAreaView
      style={[styles.container, { backgroundColor: theme.background }]}
    >
      <ScrollView>
        <Container style={styles.content}>
          <TouchableOpacity
            style={styles.back}
            onPress={() => props.navigation.goBack()}
          >
            <Ionicons color={theme.text} size={30} name="arrow-back-outline" />
          </TouchableOpacity>
          <FiraText style={styles.title}>{walk.name}</FiraText>
          <FiraText style={[styles.stat, { color: theme.textTint }]}>
            {walk.stops?.length ?? 0} Stops
          </FiraText>
          <FiraText style={[styles.stat, { color: theme.textTint }]}>
            {walk.length ?? (walk.stops?.length ?? 3) * 15} minutes
          </FiraText>
          <BlockContent blocks={walk.descriptionRaw} />
          <FiraText style={[styles.stopsLabel, { color: theme.textTint }]}>
            Stops
          </FiraText>
          <View style={styles.stops}>
            {walk.stops?.map(
              (stop: Stop) =>
                stop && (
                  <ImageButton
                    key={stop._id}
                    imageUri={resize(stop.heroImage?.asset?.url!, {
                      width: Container.width(),
                    })}
                    onPress={() => {
                      props.navigation.navigate("TrailLocation", {
                        locationId: stop._id!,
                        trailId: walk._id!,
                      });
                    }}
                  >
                    <FiraText style={styles.stopLabel}>{stop.name}</FiraText>
                  </ImageButton>
                )
            )}
          </View>
        </Container>
      </ScrollView>
    </SafeAreaView>
  );
}

export function WalkScreen(props: WalkStackNavigationProp<"Trail">) {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <WalkScreenComponent {...props} />
    </Suspense>
  );
}
