import { Ionicons } from "@expo/vector-icons";
import {
  DarkTheme,
  DefaultTheme,
  NavigationContainer,
} from "@react-navigation/native";
import * as Linking from "expo-linking";
import React from "react";
import { Platform, StyleSheet } from "react-native";
import { LoadingScreen } from "./components/LoadingScreen";
import { useLoadResources } from "./hooks/useLoadResources";
import {
  BottomTabs,
  EventStack,
  MapStack,
  ResourceStack,
  RootStack,
  TabNavigationProp,
  WalkStack,
} from "./navigation";
import { AboutScreen } from "./screens/AboutScreen";
import { EventScreen } from "./screens/EventScreen";
import { EventsScreen } from "./screens/EventsScreen";
import { ExploreScreen } from "./screens/ExploreScreen";
import { LocationScreen } from "./screens/LocationScreen";
import { MapScreen } from "./screens/MapScreen";
import { NotFoundScreen } from "./screens/NotFoundScreen";
import { PreviewScreen } from "./screens/PreviewScreen";
import { PrivacyPolicyScreen } from "./screens/PrivacyPolicyScreen";
import { ResourceScreen } from "./screens/ResourceScreen";
import { ResourcesScreen } from "./screens/ResourcesScreen";
import { WalkLocationScreen } from "./screens/WalkLocationScreen";
import { WalkScreen } from "./screens/WalkScreen";
import { WalksScreen } from "./screens/WalksScreen";
import { useColorScheme, useTheme } from "./Theme";

function MapStackNavigator() {
  return (
    <MapStack.Navigator>
      <MapStack.Screen
        name="Map"
        component={MapScreen}
        options={{ headerShown: false }}
      />
      <MapStack.Screen
        name="Location"
        component={LocationScreen}
        options={{ headerShown: false }}
      />
    </MapStack.Navigator>
  );
}

function TrailsTabStackNavigator() {
  return (
    <WalkStack.Navigator>
      <WalkStack.Screen
        name="Trails"
        component={WalksScreen}
        options={{ headerShown: false }}
      />
      <WalkStack.Screen
        name="Trail"
        component={WalkScreen}
        options={{ headerShown: false }}
      />
      <WalkStack.Screen
        name="TrailLocation"
        component={WalkLocationScreen}
        options={{ headerShown: false }}
      />
    </WalkStack.Navigator>
  );
}

function EventStackNavigator() {
  return (
    <EventStack.Navigator>
      <EventStack.Screen
        name="Events"
        component={EventsScreen}
        options={{ headerShown: false }}
      />
      <EventStack.Screen
        name="Event"
        component={EventScreen}
        options={{ headerShown: false }}
      />
    </EventStack.Navigator>
  );
}

function ResourceStackNavigator() {
  return (
    <ResourceStack.Navigator>
      <ResourceStack.Screen
        name="Resources"
        component={ResourcesScreen}
        options={{ headerShown: false }}
      />
      <ResourceStack.Screen
        name="Resource"
        component={ResourceScreen}
        options={{ headerShown: false }}
      />
    </ResourceStack.Navigator>
  );
}

const styles = StyleSheet.create({ icon: { marginBottom: -3 } });

function TabBarIcon(props: {
  name: React.ComponentProps<typeof Ionicons>["name"];
  color: string;
}) {
  return <Ionicons size={30} style={styles.icon} {...props} />;
}

const tabBarListeners = ({ navigation, route }: TabNavigationProp<any>) => ({
  tabPress: () => {
    navigation.reset({
      index: 0,
      routes: [{ name: route.name }],
    });
  },
});

function BottomTabNavigator() {
  const theme = useTheme();
  return (
    <BottomTabs.Navigator
      initialRouteName={Platform.OS === "web" ? "Explore" : "Map"}
      screenOptions={{
        // @ts-ignore The library accepts this, but the typings aren't up to date
        tabBarActiveTintColor: theme.textTint,
        tabBarStyle: [{ display: "flex" }],
      }}
    >
      <BottomTabs.Screen
        name="Explore"
        component={ExploreScreen}
        options={{
          tabBarIcon: ({ color }) => (
            <TabBarIcon name="camera-outline" color={color} />
          ),
        }}
      />
      <BottomTabs.Screen
        name="Map"
        component={MapStackNavigator}
        listeners={tabBarListeners}
        options={{
          tabBarIcon: ({ color }) => (
            <TabBarIcon name="map-outline" color={color} />
          ),
        }}
      />
      <BottomTabs.Screen
        name="TrailsTab"
        component={TrailsTabStackNavigator}
        listeners={tabBarListeners}
        options={{
          title: "Trails",
          tabBarIcon: ({ color }) => (
            <TabBarIcon name="walk-outline" color={color} />
          ),
        }}
      />
      <BottomTabs.Screen
        name="Events"
        component={EventStackNavigator}
        listeners={tabBarListeners}
        options={{
          tabBarIcon: ({ color }) => (
            <TabBarIcon name="calendar-outline" color={color} />
          ),
        }}
      />
      {Platform.OS === "web" && (
        <BottomTabs.Screen
          name="Resources"
          component={ResourceStackNavigator}
          listeners={tabBarListeners}
          options={{
            tabBarIcon: ({ color }) => (
              <TabBarIcon name="school-outline" color={color} />
            ),
          }}
        />
      )}
      <BottomTabs.Screen
        name="About"
        component={AboutScreen}
        options={{
          tabBarIcon: ({ color }) => (
            <TabBarIcon name="information-outline" color={color} />
          ),
        }}
      />
    </BottomTabs.Navigator>
  );
}

function RootNavigator() {
  return (
    <RootStack.Navigator screenOptions={{ headerShown: false }}>
      <RootStack.Screen name="Root" component={BottomTabNavigator} />
      <RootStack.Screen name="Preview" component={PreviewScreen} />
      {Platform.OS === "web" && (
        <RootStack.Screen
          name="PrivacyPolicy"
          component={PrivacyPolicyScreen}
        />
      )}
      <RootStack.Screen
        name="NotFound"
        component={NotFoundScreen}
        options={{ title: "Oops!" }}
      />
    </RootStack.Navigator>
  );
}

const LinkingConfiguration = {
  prefixes: [Linking.makeUrl("/")],
  config: {
    screens: {
      Root: {
        screens: {
          Explore: "explore",
          About: "about",
          Map: {
            screens: {
              Map: "map",
              Location: "map/:locationId",
            },
          },
          TrailsTab: {
            screens: {
              Trails: "trails",
              Trail: "trails/:trailId",
              TrailLocation: "trails/:trailId/:locationId",
            },
          },
          More: { screens: { More: "more" } },
          Events: {
            screens: {
              Events: "events",
              Event: "events/:eventId",
            },
          },
          Resources: {
            screens: {
              Resources: "resources",
              Resource: "resources/:resourceId",
            },
          },
        },
      },
      Preview: "preview",
      PrivacyPolicy: "privacy",
      NotFound: "*",
    },
  },
};

export function DigitalTrailblazers() {
  useLoadResources();
  const colorScheme = useColorScheme();
  return (
    <NavigationContainer
      fallback={<LoadingScreen />}
      linking={LinkingConfiguration}
      theme={colorScheme === "dark" ? DarkTheme : DefaultTheme}
    >
      <RootNavigator />
    </NavigationContainer>
  );
}
