import React, { Suspense } from "react";
import { LogBox, Platform } from "react-native";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { RelayEnvironmentProvider } from "react-relay";
import * as Sentry from "sentry-expo";
import { LoadingScreen } from "./sources/components/LoadingScreen";
import { DigitalTrailblazers } from "./sources/DigitalTrailblazers";
import { environment } from "./sources/RelayEnvironment";

Sentry.init({
  dsn: "https://e64ba51d1d8b4261af1e8918a172e0e3@o282708.ingest.sentry.io/5724797",
});

declare global {
  interface Window {
    dataLayer: any[]
  }
}

if (Platform.OS !== "web") {
  // Android only warning
  LogBox.ignoreLogs(["Setting a timer for a long"]);
} else {
  window.dataLayer = window.dataLayer || [];
  function gtag(...args: any[]) {
    window.dataLayer.push(args);
  }
  gtag("js", new Date());
  gtag("config", "G-QWHWMTSWH9");
}

export default function App() {
  return (
    <RelayEnvironmentProvider environment={environment() as any}>
      <SafeAreaProvider>
        <Suspense fallback={<LoadingScreen />}>
          <DigitalTrailblazers />
        </Suspense>
      </SafeAreaProvider>
    </RelayEnvironmentProvider>
  );
}
