import haversine from "haversine-distance";

export type LatLng = { lat: number; lng: number };

type Bounds = {
  northEast: LatLng;
  southWest: LatLng;
};

export const boundary = (
  points: Array<LatLng>,
  paddingPercentage: number = 0,
): Bounds => {
  let latMin = Number.POSITIVE_INFINITY;
  let latMax = Number.NEGATIVE_INFINITY;
  let lngMin = Number.POSITIVE_INFINITY;
  let lngMax = Number.NEGATIVE_INFINITY;

  points.forEach((point) => {
    if (point.lat < latMin) {
      latMin = point.lat;
    }
    if (point.lng < lngMin) {
      lngMin = point.lng;
    }
    if (point.lat > latMax) {
      latMax = point.lat;
    }
    if (point.lng > lngMax) {
      lngMax = point.lng;
    }
  });

  const size = Math.max(latMax - latMin, lngMax - lngMin);
  const padding = size * Math.max(0, paddingPercentage);

  return {
    northEast: {
      lat: latMax + padding / 2,
      lng: lngMax + padding / 2,
    },
    southWest: {
      lat: latMin - padding / 2,
      lng: lngMin - padding / 2,
    },
  };
};

const equatorialRadiusOfTheEarthMeters = 40075016.686;

// See https://wiki.openstreetmap.org/wiki/Zoom_levels
export const zoomLevelFor = (
  dimensions: { width: number; height: number },
  bounds: Bounds,
) => {
  const center = {
    lat: (bounds.northEast.lat + bounds.southWest.lat) / 2,
    lng: (bounds.northEast.lng + bounds.southWest.lng) / 2,
  };
  const horizontalDistance = haversine(
    { lat: center.lat, lng: bounds.northEast.lng },
    {
      lat: center.lat,
      lng: bounds.southWest.lng,
    },
  );
  const verticalDistance = haversine(
    { lat: bounds.northEast.lat, lng: center.lng },
    {
      lat: bounds.southWest.lat,
      lng: center.lng,
    },
  );

  const metersPerPixel = Math.max(
    verticalDistance / dimensions.height,
    horizontalDistance / dimensions.width,
  );

  const zoomLevel =
    Math.log(
      (equatorialRadiusOfTheEarthMeters *
        Math.cos((center.lat * Math.PI) / 180)) /
        metersPerPixel,
    ) /
      Math.log(2) -
    8;

  return zoomLevel;
};
