/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type PreviewScreenQueryVariables = {
    documentId: string;
};
export type PreviewScreenQueryResponse = {
    readonly Document: {
        readonly _id: string | null;
        readonly _type: string | null;
    } | null;
};
export type PreviewScreenQuery = {
    readonly response: PreviewScreenQueryResponse;
    readonly variables: PreviewScreenQueryVariables;
};



/*
query PreviewScreenQuery(
  $documentId: ID!
) {
  Document(id: $documentId) {
    __typename
    _id
    _type
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "documentId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "documentId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_type",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PreviewScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "Document",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PreviewScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "Document",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7daa3cf94b44fa78fcdb4c0f3a73be4b",
    "id": null,
    "metadata": {},
    "name": "PreviewScreenQuery",
    "operationKind": "query",
    "text": "query PreviewScreenQuery(\n  $documentId: ID!\n) {\n  Document(id: $documentId) {\n    __typename\n    _id\n    _type\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd53639f7c75598c129bf92973034d238';
export default node;
