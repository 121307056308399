import { format, formatISO, parseISO, subDays } from "date-fns";
import React, { Suspense } from "react";
import { SafeAreaView, ScrollView, StyleSheet, View as RNView } from "react-native";
import { graphql, useLazyLoadQuery } from "react-relay";
import { BlockContent } from "../components/BlockContent";
import { Container } from "../components/Container";
import { ImageButton } from "../components/ImageButton";
import { LoadingScreen } from "../components/LoadingScreen";
import { FiraText, OpenText } from "../components/Themed";
import { resize } from "../config";
import { EventStackNavigationProp } from "../navigation";
import { Colors, Fonts, Spacings, useTheme } from "../Theme";
import { ElementOf } from "../types";
import { EventsScreenQuery, EventsScreenQueryResponse } from "./__generated__/EventsScreenQuery.graphql";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
  },
  eventDate: {
    color: Colors.white,
    fontSize: Fonts.h5,
  },
  eventTitle: {
    color: Colors.white,
    fontSize: Fonts.h3,
    fontWeight: "bold",
  },
  events: {
    flexDirection: "column",
    marginBottom: Spacings.small,
  },
  title: {
    color: Colors.orange,
    fontSize: Fonts.h2,
    fontWeight: "bold",
    marginVertical: Spacings.large,
  },
});

type Event = ElementOf<EventsScreenQueryResponse["allEvent"]>;

export function EventsScreenComponent(
  props: EventStackNavigationProp<"Events">
) {
  const theme = useTheme();
  const dateAfter = formatISO(subDays(new Date(), 2), {
    representation: "date",
  });
  const data = useLazyLoadQuery<EventsScreenQuery>(
    graphql`
      query EventsScreenQuery($dateAfter: Date!, $documentId: ID!) {
        Events(id: $documentId) {
          _id
          name
          contentRaw
        }
        allEvent(where: { date: { gt: $dateAfter } }, sort: [{ date: ASC }]) {
          _id
          date
          name
          color {
            hex
          }
          heroImage {
            asset {
              url
            }
          }
        }
      }
    `,
    {
      dateAfter,
      documentId: props.route.params?.documentId ?? "events",
    }
  );
  const events = data.allEvent!;
  return (
    <SafeAreaView
      style={[styles.container, { backgroundColor: theme.background }]}
    >
      <ScrollView>
        <Container>
          <FiraText style={styles.title}>Events</FiraText>
          {data.Events && (
            <BlockContent blocks={data.Events?.contentRaw} />
          )}
          <RNView style={styles.events}>
            {events.length === 0 && (
              <OpenText>No upcoming events, check back soon!</OpenText>
            )}
            {events.map(
              (event: Event) =>
                event && (
                  <ImageButton
                    key={event._id}
                    imageUri={resize(event.heroImage?.asset?.url!, {
                      width: Container.width(),
                    })}
                    onPress={() => {
                      props.navigation.navigate("Event", {
                        // @ts-ignore TODO messed up navigator typings?
                        eventId: event._id!,
                      });
                    }}
                  >
                    <FiraText style={styles.eventTitle}>{event.name}</FiraText>
                    <OpenText style={styles.eventDate}>
                      {format(parseISO(event.date as string), "dd/MM/YYY")}
                    </OpenText>
                  </ImageButton>
                )
            )}
          </RNView>
        </Container>
      </ScrollView>
    </SafeAreaView>
  );
}

export function EventsScreen(props: EventStackNavigationProp<"Events">) {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <EventsScreenComponent {...props} />
    </Suspense>
  );
}
