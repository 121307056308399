/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type WalksScreenQueryVariables = {};
export type WalksScreenQueryResponse = {
    readonly allWalk: ReadonlyArray<{
        readonly _id: string | null;
        readonly name: string | null;
        readonly isCircuit: boolean | null;
        readonly length: number | null;
        readonly stops: ReadonlyArray<{
            readonly _id: string | null;
            readonly icon: {
                readonly asset: {
                    readonly url: string | null;
                } | null;
            } | null;
            readonly color: {
                readonly hex: string | null;
            } | null;
            readonly heroImage: {
                readonly asset: {
                    readonly url: string | null;
                } | null;
            } | null;
            readonly location: {
                readonly lat: number | null;
                readonly lng: number | null;
            } | null;
        } | null> | null;
    }>;
};
export type WalksScreenQuery = {
    readonly response: WalksScreenQueryResponse;
    readonly variables: WalksScreenQueryVariables;
};



/*
query WalksScreenQuery {
  allWalk {
    _id
    name
    isCircuit
    length
    stops {
      _id
      icon {
        asset {
          url
        }
      }
      color {
        hex
      }
      heroImage {
        asset {
          url
        }
      }
      location {
        lat
        lng
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SanityImageAsset",
    "kind": "LinkedField",
    "name": "asset",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Walk",
    "kind": "LinkedField",
    "name": "allWalk",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isCircuit",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "length",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Location",
        "kind": "LinkedField",
        "name": "stops",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "icon",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Color",
            "kind": "LinkedField",
            "name": "color",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hex",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "heroImage",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Geopoint",
            "kind": "LinkedField",
            "name": "location",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lat",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lng",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "WalksScreenQuery",
    "selections": (v2/*: any*/),
    "type": "RootQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "WalksScreenQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "b9d1b98802cfdfa644d89f386e7338df",
    "id": null,
    "metadata": {},
    "name": "WalksScreenQuery",
    "operationKind": "query",
    "text": "query WalksScreenQuery {\n  allWalk {\n    _id\n    name\n    isCircuit\n    length\n    stops {\n      _id\n      icon {\n        asset {\n          url\n        }\n      }\n      color {\n        hex\n      }\n      heroImage {\n        asset {\n          url\n        }\n      }\n      location {\n        lat\n        lng\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'cca1d18a995c640ea90f3014f01094de';
export default node;
