import { Ionicons } from "@expo/vector-icons";
import React, { ReactNode } from "react";
import { ImageBackground, StyleSheet, TouchableOpacity, View as RNView } from "react-native";
import { Colors, Spacings, useTheme } from "../Theme";

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    borderWidth: 1,
    flexDirection: "row",
    marginBottom: Spacings.medium,
  },
  content: {
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.3)",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: Spacings.small,
    paddingVertical: Spacings.small,
  },
  children: {
    flex: 1,
  },
  icon: { marginBottom: -3 },
  image: { resizeMode: "cover" },
  imageContainer: { flex: 1 },
});

export const ImageButton = (props: {
  children?: ReactNode;
  imageUri: string;
  onPress: () => void;
}) => {
  const theme = useTheme();
  return (
    <TouchableOpacity
      style={[styles.container, { borderColor: theme.text }]}
      onPress={props.onPress}
    >
      <ImageBackground
        source={{
          uri: props.imageUri,
        }}
        imageStyle={styles.image}
        style={styles.imageContainer}
      >
        <RNView style={styles.content}>
          <RNView style={styles.children}>{props.children}</RNView>
          <Ionicons
            color={Colors.white}
            size={30}
            style={styles.icon}
            name="arrow-forward-outline"
          />
        </RNView>
      </ImageBackground>
    </TouchableOpacity>
  );
};
