import { Ionicons } from "@expo/vector-icons";
import React, { ReactNode } from "react";
import {
  Linking,
  Platform,
  StyleProp,
  StyleSheet,
  TextStyle,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
  ViewStyle
} from "react-native";
import { Colors, Fonts, Spacings } from "../Theme";
import { OpenText } from "./Themed";

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    backgroundColor: Colors.purple4,
    justifyContent: "center",
    paddingHorizontal: Spacings.small,
    paddingVertical: Spacings.small,
  },
  label: {
    color: Colors.white,
    fontSize: Fonts.body,
    fontWeight: "bold",
    textAlign: "center",
    textTransform: "uppercase",
  },
  linkLabel: {},
});

export const FileLinkButton = (props: {
  label: string;
  url: string;
  style?: StyleProp<TextStyle>;
}) => {
  if (Platform.OS === "web") {
    return (
      <TouchableWithoutFeedback>
        <View>
          <OpenText
            accessibilityRole="link"
            // @ts-ignore Text transparently becomes an `a` tag on the web which accepts an href prop
            href={props.url}
            style={[styles.container, props.style]}
            target="_blank"
          >
            <View
              style={{
                alignItems: "center",
                flex: 1,
                flexDirection: "column",
                width: "100%",
              }}
            >
              <OpenText style={styles.label}>{props.label}</OpenText>
              <Ionicons
                color={Colors.white}
                name={"cloud-download-outline"}
                size={Fonts.body}
              />
            </View>
          </OpenText>
        </View>
      </TouchableWithoutFeedback>
    );
  }

  return (
    <Button
      onPress={() => {
        Linking.openURL(props.url);
      }}
      style={props.style}
    >
      <ButtonLabel>{props.label}</ButtonLabel>
      <Ionicons
        name={"cloud-download-outline"}
        color={Colors.white}
        size={Fonts.body}
      />
    </Button>
  );
};

export const Button = (props: {
  children?: ReactNode;
  onPress: () => void;
  style?: StyleProp<ViewStyle>;
}) => {
  return (
    <TouchableOpacity
      activeOpacity={0.7}
      style={[styles.container, props.style]}
      onPress={props.onPress}
    >
      {props.children}
    </TouchableOpacity>
  );
};

export const ButtonLabel = (props: {
  children?: ReactNode;
  style?: StyleProp<TextStyle>;
}) => <OpenText style={[styles.label, props.style]}>{props.children}</OpenText>;
