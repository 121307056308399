/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ExploreScreenQueryVariables = {
    documentId: string;
};
export type ExploreScreenQueryResponse = {
    readonly Explore: {
        readonly _id: string | null;
        readonly name: string | null;
        readonly contentRaw: unknown | null;
    } | null;
    readonly allLocation: ReadonlyArray<{
        readonly _id: string | null;
        readonly color: {
            readonly hex: string | null;
        } | null;
        readonly heroImage: {
            readonly asset: {
                readonly url: string | null;
            } | null;
        } | null;
    }>;
};
export type ExploreScreenQuery = {
    readonly response: ExploreScreenQueryResponse;
    readonly variables: ExploreScreenQueryVariables;
};



/*
query ExploreScreenQuery(
  $documentId: ID!
) {
  Explore(id: $documentId) {
    _id
    name
    contentRaw
  }
  allLocation {
    _id
    color {
      hex
    }
    heroImage {
      asset {
        url
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "documentId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "documentId"
      }
    ],
    "concreteType": "Explore",
    "kind": "LinkedField",
    "name": "Explore",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "contentRaw",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Location",
    "kind": "LinkedField",
    "name": "allLocation",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Color",
        "kind": "LinkedField",
        "name": "color",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hex",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Image",
        "kind": "LinkedField",
        "name": "heroImage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SanityImageAsset",
            "kind": "LinkedField",
            "name": "asset",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExploreScreenQuery",
    "selections": (v2/*: any*/),
    "type": "RootQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExploreScreenQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "00cb8eb162cdf4222da4b8dc07066ac1",
    "id": null,
    "metadata": {},
    "name": "ExploreScreenQuery",
    "operationKind": "query",
    "text": "query ExploreScreenQuery(\n  $documentId: ID!\n) {\n  Explore(id: $documentId) {\n    _id\n    name\n    contentRaw\n  }\n  allLocation {\n    _id\n    color {\n      hex\n    }\n    heroImage {\n      asset {\n        url\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'adbbc2bdc5a3b8b0775d0cdab17bccc6';
export default node;
