import React from "react";
import { StyleSheet, Text as DefaultText, View as DefaultView } from "react-native";
import { Fonts, useThemeColor } from "../Theme";

type ThemeProps = {
  lightColor?: string;
  darkColor?: string;
};

const textStyles = StyleSheet.create({
  fira: {
    fontFamily: "FiraSans_900Black",
    fontSize: Fonts.h6,
  },
  openSans: {
    fontFamily: "OpenSans_400Regular",
  },
});

export type TextProps = ThemeProps & DefaultText["props"];

export function FiraText(props: TextProps) {
  const { style, lightColor, darkColor, ...otherProps } = props;
  const color = useThemeColor({ light: lightColor, dark: darkColor }, "text");

  return (
    <DefaultText style={[textStyles.fira, { color }, style]} {...otherProps} />
  );
}

export function OpenText(props: TextProps) {
  const { style, lightColor, darkColor, ...otherProps } = props;
  const color = useThemeColor({ light: lightColor, dark: darkColor }, "text");

  return (
    <DefaultText
      style={[textStyles.openSans, { color }, style]}
      {...otherProps}
    />
  );
}

export type ViewProps = ThemeProps & DefaultView["props"];

export function View(props: ViewProps) {
  const { style, lightColor, darkColor, ...otherProps } = props;
  const backgroundColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    "background",
  );

  return <DefaultView style={[{ backgroundColor }, style]} {...otherProps} />;
}
