/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type EventsScreenQueryVariables = {
    dateAfter: unknown;
    documentId: string;
};
export type EventsScreenQueryResponse = {
    readonly Events: {
        readonly _id: string | null;
        readonly name: string | null;
        readonly contentRaw: unknown | null;
    } | null;
    readonly allEvent: ReadonlyArray<{
        readonly _id: string | null;
        readonly date: unknown | null;
        readonly name: string | null;
        readonly color: {
            readonly hex: string | null;
        } | null;
        readonly heroImage: {
            readonly asset: {
                readonly url: string | null;
            } | null;
        } | null;
    }>;
};
export type EventsScreenQuery = {
    readonly response: EventsScreenQueryResponse;
    readonly variables: EventsScreenQueryVariables;
};



/*
query EventsScreenQuery(
  $dateAfter: Date!
  $documentId: ID!
) {
  Events(id: $documentId) {
    _id
    name
    contentRaw
  }
  allEvent(where: {date: {gt: $dateAfter}}, sort: [{date: ASC}]) {
    _id
    date
    name
    color {
      hex
    }
    heroImage {
      asset {
        url
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dateAfter"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "documentId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "documentId"
      }
    ],
    "concreteType": "Events",
    "kind": "LinkedField",
    "name": "Events",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "contentRaw",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "sort",
        "value": [
          {
            "date": "ASC"
          }
        ]
      },
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "gt",
                "variableName": "dateAfter"
              }
            ],
            "kind": "ObjectValue",
            "name": "date"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "Event",
    "kind": "LinkedField",
    "name": "allEvent",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "date",
        "storageKey": null
      },
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Color",
        "kind": "LinkedField",
        "name": "color",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hex",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Image",
        "kind": "LinkedField",
        "name": "heroImage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SanityImageAsset",
            "kind": "LinkedField",
            "name": "asset",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EventsScreenQuery",
    "selections": (v3/*: any*/),
    "type": "RootQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EventsScreenQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "4b5d09eef35937b1efc4e6cafce33d83",
    "id": null,
    "metadata": {},
    "name": "EventsScreenQuery",
    "operationKind": "query",
    "text": "query EventsScreenQuery(\n  $dateAfter: Date!\n  $documentId: ID!\n) {\n  Events(id: $documentId) {\n    _id\n    name\n    contentRaw\n  }\n  allEvent(where: {date: {gt: $dateAfter}}, sort: [{date: ASC}]) {\n    _id\n    date\n    name\n    color {\n      hex\n    }\n    heroImage {\n      asset {\n        url\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'f4dd2af61b7196303dc86b2e664afbc4';
export default node;
