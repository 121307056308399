import Constants from "expo-constants";
import React from "react";
import { StyleSheet, Text } from "react-native";

const styles = StyleSheet.create({
  text: {
    bottom: 10,
    color: "rgba(255, 255, 255, 0.2)",
    fontSize: 9,
    position: "absolute",
    right: 10,
  },
});


export const Version = () => {
  return (
    <Text style={styles.text}>
      v{Constants.manifest?.version}
    </Text>
  );
};
