const environment = __DEV__ ? "development" : "production";

const projectId = "4cta2ln7";

export const buildFileUrl = (source: string) => {
  const [_type, assetId, extension] = source.split("-");
  // TODO report an error if we don't end up with three parts
  return `https://cdn.sanity.io/files/${projectId}/production/${assetId}.${extension}`;
};

export const buildImageUrl = (
  source: string,
  dimensions: { width: number },
) => {
  const [_type, assetId, originalDimensions, extension] = source.split("-");
  // TODO report an error if we don't end up with four parts
  return `https://cdn.sanity.io/images/${projectId}/production/${assetId}-${originalDimensions}.${extension}?w=${dimensions.width}`;
};

export const resize = (imageUrl: string, dimensions: { width: number }) => {
  return `${imageUrl}?w=${dimensions.width * 2}&fit=max`;
};

export const graphQlApi = `https://${projectId}.api.sanity.io/v1/graphql/production/default`;
export const graphQlCdnApi = `https://${projectId}.apicdn.sanity.io/v1/graphql/production/default`;

export const config = {
  enableGraphQlQueryLogging: __DEV__ && false,
  enableStorageLogging: __DEV__ && false,
  environment,
  // The GraphiQL explorer is available at https://4cta2ln7.api.sanity.io/v1/graphql/production/default
  // These urls follow the pattern dictated here: https://www.sanity.io/docs/graphql#7fc7bbeca01e
  // 'https://<yourProjectId>.api<cdn?>.sanity.io/v1/graphql/<dataset>/<tag>',
  graphQlApi,
  graphQlCdnApi,
  mapboxToken:
    "pk.eyJ1IjoiZGVhbm1lcmNoYW50IiwiYSI6ImNrbnVxbm10bjBmZGQydXJtbmY2Y2hlMnIifQ.SESz2gD20hshTnkQJtqLGg",
  sentryDsn:
    "https://e64ba51d1d8b4261af1e8918a172e0e3@o282708.ingest.sentry.io/5724797",
};
